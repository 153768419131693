import makeRequest from '@api/makeRequest'

export const getAllFavoritesApi = () => {
  return makeRequest({
    url: '/api/lk/favorites'
  })
}

export const removeFavoriteProductApi = (id: number) => {
  return makeRequest({
    url: '/api/lk/favorites/' + id,
    method: 'delete',
  })
}
