import React from 'react'
import { Link } from 'react-router-dom'
import styles from './link-button.module.scss'

interface props {
  url: string | {}
  ghost?: boolean
  children: React.ReactNode
}

const LinkButton: React.FC<props> = ({ url, children, ghost = false }) => {
  return (
    <Link to={url} className={[styles.link, ghost ? styles.ghost : ''].join(' ')}>
      {children}
    </Link>
  )
}

export default React.memo(LinkButton)
