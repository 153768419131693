import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { Card, Col, PageHeader, Row, Skeleton, Tabs } from 'antd'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import { ProfileOrganizations, ProfilePersonalInformation } from './components'

const { TabPane } = Tabs

interface props {}

const ProfilePage: React.FC<props> = () => {
  const { data, isLoading, isError } = useAuthUserInformation()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const title = 'Персональные данные'

  const { holding, ...personalInfo } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />

      <Container>
        <PageHeader title={title} />
        <Card title={<strong>{title}</strong>}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Личные данные" key="1">
              <Row>
                <Col xs={24} xxl={12}>
                  <ProfilePersonalInformation data={personalInfo} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Данные компании" key="2">
              <Row>
                <Col xs={24} xxl={12}>
                  <ProfileOrganizations organizations={holding.organizations} />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default ProfilePage
