import React from 'react'
import styles from './change-cart-status-button.module.scss'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { setActiveCartApi } from '@api/rest/active-cart'
import { queryClient } from '@app/index'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'
import { CART_KEY } from '@app/hooks/query/active-cart'

interface props {
  id: number
  active: boolean
}

const ChangeCartStatusButton: React.FC<props> = ({ id, active }) => {
  const { isLoading, mutateAsync } = useMutation(setActiveCartApi, {
    onSuccess: async () => {
      message.success('Активная корзина успешно изменена')
      await queryClient.resetQueries(ALL_CARTS_KEY)
      await queryClient.invalidateQueries(CART_KEY)
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте, пожалуйста, позже')
    },
  })

  const changeStatusHandler = async () => {
    await mutateAsync(id)
  }
  return (
    <Button
      size={'large'}
      className={styles.changeStatusButton}
      type={active ? 'default' : 'primary'}
      ghost={!active}
      disabled={active || isLoading}
      loading={isLoading}
      onClick={changeStatusHandler}
    >
      {active ? 'Активна' : 'Переключиться'}
    </Button>
  )
}

export default React.memo(ChangeCartStatusButton)
