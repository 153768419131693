import React from 'react'
import styles from './product-status.module.scss'
import { CanceledIcon, CheckIcon } from '@app/shared'

interface props {
  inStock: number
  productId: number
}

const ProductStatus: React.FC<props> = ({ inStock, productId }) => {
  // const { id } = useParams<{ id: string }>()

  // const { isLoading, mutateAsync } = useMutation(postponeProductApi, {
  //   onSuccess: async () => {
  //     await queryClient.invalidateQueries([ORDERS_KEY, id])
  //     message.success('Продукт успешно отложен')
  //   },
  //   onError: () => {
  //     message.error('Ошибка! Что-то пошло не так')
  //   },
  // })

  // const confirmPostpone = () => mutateAsync({ orderId: parseInt(id), productId })

  return (
    <div className={styles.wrapper}>
      <span>
        {inStock ? (
          <>
            <CheckIcon /> В работе
          </>
        ) : (
          <>
            <CanceledIcon /> Нет в наличии
          </>
        )}
      </span>
      {/*{inStock < 1 && (*/}
      {/*  <React.Fragment>*/}
      {/*    <Divider type={'vertical'} />*/}
      {/*    <Popconfirm*/}
      {/*      title="Вы уверены, что хотите отложить этот товар?"*/}
      {/*      okText="Да"*/}
      {/*      cancelText="Нет"*/}
      {/*      placement={'bottom'}*/}
      {/*      disabled={isLoading}*/}
      {/*      onConfirm={confirmPostpone}*/}
      {/*    >*/}
      {/*      <span className={styles.postpone} >*/}
      {/*        <SaveOutlined /> Отложить товар*/}
      {/*      </span>*/}
      {/*    </Popconfirm>*/}
      {/*  </React.Fragment>*/}
      {/*)}*/}
    </div>
  )
}

export default React.memo(ProductStatus)
