import React from 'react'
import { ICart, ICartCounterProps } from '@app/interfaces/cart'
import { CartInfo, ProductListTable, RemoveFromCart } from '@app/shared'
import styles from './saved-cart.module.scss'
import isEqual from 'react-fast-compare'
import { ChangeCartStatusButton, SavedCartDropdown, SavedCartsCounter } from '../index'
import { Divider, Space } from 'antd'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'
import CheckoutSavedCartButton from '../checkout-saved-cart-button/checkout-saved-cart-button'

interface props {
  cart: ICart
}

const SavedCart: React.FC<props> = ({ cart }) => {
  const { items, created_at, updated_at, active, order, id, ...info } = cart

  const tableTitle = () => (
    <div className={styles.header}>
      <div>
        Корзина от {new Date(created_at).toLocaleDateString()}
        {order && (
          <React.Fragment>
            <Divider type={'vertical'} /> Заказ: №{order.id}
          </React.Fragment>
        )}
      </div>
      <SavedCartDropdown cartId={cart.id} />
    </div>
  )
  const tableFooter = () => (
    <CartInfo cartInfo={info}>
      <Space size={'middle'}>
        <CheckoutSavedCartButton id={id} active={active} />
        <ChangeCartStatusButton id={id} active={active} />
      </Space>
    </CartInfo>
  )
  const counterFunction = (props: ICartCounterProps) => <SavedCartsCounter {...props} cartId={id} />
  const removeProductFunction = (productId: number) => (
    <RemoveFromCart productId={productId} cartId={id} />
  )

  return (
    <ProductListTable
      asyncSorting={false}
      invalidateQueryKey={ALL_CARTS_KEY}
      counterFunction={counterFunction}
      removeProductFunction={removeProductFunction}
      data={items}
      tableTitle={tableTitle}
      tableFooter={tableFooter}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.cart, nextProps.cart)
}

export default React.memo(SavedCart, propsAreEqual)
