import React from 'react'
import { InformationCard, LinkButton } from '@app/shared'
import { IUserManager } from '@app/interfaces/user'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import styles from './manager-info.module.scss'
import { Col, Row, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

interface props {
  managers: IUserManager[]
}

const ManagerInfo: React.FC<props> = ({ managers }) => {
  return (
    <div className={styles.cardWrapper}>
      <InformationCard title={'Ваш менеджер'}>
        <Row>
          {managers.map(({ id, image_url, full_name, email }) => (
            <Col className={styles.box} xs={24} xxl={12} key={id}>
              <div className={styles.managerInfo} key={id}>
                <div className={styles.imageBox}>
                  {image_url ? (
                    <img
                      className={styles.image}
                      src={image_url ?? undefined}
                      width={80}
                      height={80}
                      alt={''}
                    />
                  ) : (
                    <UserOutlined />
                  )}
                </div>
                <div className={styles.info}>
                  <Typography.Text className={styles.text}>{full_name}</Typography.Text>
                  <Typography.Text className={styles.position}>Ассистент</Typography.Text>
                  {email ? (
                    <a target={'_blank'} rel="noreferrer" href={`mailto:${email}`}>
                      {email}
                    </a>
                  ) : (
                    <Typography.Text type={'secondary'}>Информация отсутствует</Typography.Text>
                  )}
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <LinkButton url={DashboardRoutes.SUPPORT} ghost={true}>
                  Задать вопрос
                </LinkButton>
              </div>
            </Col>
          ))}
        </Row>
      </InformationCard>
    </div>
  )
}

export default ManagerInfo
