import React, { useMemo, useState } from 'react'
import styles from './catalog-parameters.module.scss'
import { Checkbox, Col, message, Row } from 'antd'
import { ICatalogFiltersTags } from '@app/interfaces/filters'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useHistory } from 'react-router-dom'
import { serialize } from '@app/utils/serializeQuery'
import { CATALOG_KEY } from '@app/hooks/query/catalog'
import _ from 'lodash'
import { queryClient } from '@app/index'
import { getQueryParams } from '@app/utils/getQueryParams'
import { IProductWithPaginationResponse } from '@app/interfaces'
import { queryKeys } from '@app/constants/query-keys'

interface props {
  tags: ICatalogFiltersTags[]
}

const CatalogParameters: React.FC<props> = ({ tags }) => {
  const [disabled, setDisabled] = useState<boolean>(false)
  const { push } = useHistory()

  const tagClickHandler = async (checkedValue: CheckboxValueType[]) => {
    setDisabled(true)

    await push({
      search: serialize(queryKeys.TAGS, checkedValue.join(',')),
    })

    await queryClient
      .fetchQuery<IProductWithPaginationResponse>(CATALOG_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно!')
      })
      .catch(() => {
        message.error('Произошла ошибка! Попробуйте повторить позже')
      })

    setDisabled(false)
  }

  const debounceCheck = useMemo(() => _.debounce(tagClickHandler, 800), [])

  // Получение отмеченных тэгов из query
  const queryTags = getQueryParams(queryKeys.TAGS)

  return (
    <div className={styles.params}>
      <div className={styles.title}>Параметры</div>
      <div className={styles.content}>
        <Checkbox.Group
          onChange={debounceCheck}
          defaultValue={queryTags ? queryTags.split(',') : []}
        >
          <Row gutter={[0, 10]}>
            {tags.map((tag) => (
              <Col key={tag.id} span={24}>
                <Checkbox value={tag.id.toString()} disabled={disabled}>
                  {tag.title}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    </div>
  )
}

export default React.memo(CatalogParameters)
