import React from 'react'
import { Table } from 'antd'
import { ProductNameWithImage, ProductStatus, TableMobileTitle } from '@app/shared'
import { IProduct, IStock } from '@app/interfaces'

interface props {
  data: IProduct[]
}

const OrderTable: React.FC<props> = ({ data }) => {
  const columns = [
    {
      title: 'Наименование',
      key: 'name',
      render: ({ title, sku, tags, brand, image_path }: IProduct) => (
        <ProductNameWithImage
          promotags={tags}
          title={title}
          imagePath={image_path}
          sku={sku}
          brand={brand?.title}
        />
      ),
    },
    {
      title: 'Цена за шт.',
      dataIndex: 'price',
      render: (text: any) => (
        <span>
          <TableMobileTitle title={'Цена за шт.'} /> <strong>{text} Руб.</strong>
        </span>
      ),
    },
    {
      title: 'Кол-во',
      dataIndex: 'quantity',
      render: (text: any) => (
        <span>
          <TableMobileTitle title={'Количество'} /> {text}
        </span>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      render: (text: any) => (
        <span>
          <TableMobileTitle title={'Сумма'} /> <strong>{text} Руб.</strong>
        </span>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'stock',
      render: (stock: IStock, record: IProduct) => (
        <span>
          <TableMobileTitle title={'Статус'} />
          <ProductStatus inStock={stock.stock} productId={record.id} />
        </span>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      scroll={{ x: true }}
      rowKey={'id'}
      pagination={false}
      dataSource={data}
    />
  )
}

export default OrderTable
