import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { useOrder } from '@app/hooks/query/order'
import { useParams } from 'react-router-dom'
import { OrderAdjustmentForm } from './components'

interface props {}

const OrderAdjustmentPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useOrder(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { items, id: orderId } = data!

  return (
    <React.Fragment>
      <PageInfo title={'Корректировка заказа'} />
      <Container>
        <PageHeader title={'Корректировка заказа'} />
        <OrderAdjustmentForm items={items} orderId={orderId} />
      </Container>
    </React.Fragment>
  )
}

export default OrderAdjustmentPage
