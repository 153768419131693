import React from 'react'
import { RemoveButton } from '@app/shared'

interface props {
  productId: number
  removeProduct: (productId: number) => void
}

const OrderAdjustmentRemove: React.FC<props> = ({ productId, removeProduct }) => {
  const submitFunction = () => removeProduct(productId)

  return <RemoveButton submitFunction={submitFunction} />
}

export default React.memo(OrderAdjustmentRemove)
