import React from 'react'
import styles from './product-image.module.scss'
import placeholder from './placeholder.svg'

interface props {
  maxWidth?: number
  imagePath?: string
}

const ProductImage: React.FC<props> = ({ maxWidth, imagePath }) => {
  return (
    <div className={styles.box} style={{ maxWidth: maxWidth || 65 }}>
      <img src={imagePath || placeholder} width={65} alt={''} />
    </div>
  )
}

export default ProductImage
