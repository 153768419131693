import React from 'react'
import styles from './order-card.module.scss'
import { OrderTable } from '../index'
import { Col, Row } from 'antd'
import { IOrder } from '@app/interfaces/orders'
import { priceFormatter } from '@app/utils/priceFormatter'
import { OrderStatus } from '@app/pages/orders-page/components'
import { NoDataText } from '@app/shared'

interface props {
  order: IOrder
}

const OrderCard: React.FC<props> = ({ order }) => {
  const { organization, user, status, number, updated_at, total, items, created_at } = order

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <div className={styles.title}>Информация о заказе</div>
        <div className={styles.info}>
          <Row gutter={[20, 20]}>
            <Col flex={1}>
              <div>
                <strong className={styles.subTitle}>Юр.лицо</strong>
                <span>{organization.title}</span>
              </div>
            </Col>
            <Col flex={1}>
              <div>
                <strong className={styles.subTitle}>Оформил заказ</strong>
                {user ? <span>{user.name}</span> : <NoDataText />}
              </div>
            </Col>
            <Col flex={1}>
              <div>
                <strong className={styles.subTitle}>Ассистент менеджера</strong>
                <NoDataText />
              </div>
            </Col>
            <Col flex={1}>
              <div>
                <strong className={styles.subTitle}>Текущий статус</strong>
                <OrderStatus
                  statusTitle={status.title}
                  orderUpdateDate={updated_at}
                  statusCode={status.code}
                />
              </div>
            </Col>
            <Col flex={1}>
              <div>
                <strong className={styles.subTitle}>Сумма</strong>
                <span>{priceFormatter(total)} руб.</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.title}>Содержимое заказа</div>
        <OrderTable data={items} />
      </div>
    </div>
  )
}

export default OrderCard
