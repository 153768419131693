import React from 'react'
import styles from './layout-header-exit.module.scss'
import icons from '@assets/images/icons.svg'
import { LoadingOutlined } from '@ant-design/icons'
import { useLogout } from '@app/hooks/useLogout'

interface props {}

const LayoutHeaderExit: React.FC<props> = () => {
  const { isLoading, logout } = useLogout()

  return (
    <button onClick={logout} className={styles.button} disabled={isLoading}>
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <svg width={20} height={20} className={styles.icon}>
          <use href={icons + '#logout'} />
        </svg>
      )}
    </button>
  )
}

export default LayoutHeaderExit
