import React, { useEffect, useState } from 'react'
import styles from './catalog-tags.module.scss'
import { useCatalogFilters } from '@app/hooks/query/filters'
import { ICatalogFiltersCategory } from '@app/interfaces/filters'
import { useHistory, useLocation } from 'react-router-dom'
import { serialize } from '@app/utils/serializeQuery'
import { queryClient } from '@app/index'
import { CATALOG_KEY } from '@app/hooks/query/catalog'
import { IProductWithPaginationResponse } from '@app/interfaces'
import { message } from 'antd'
import { queryKeys } from '@app/constants/query-keys'
import { getQueryParams } from '@app/utils/getQueryParams'
import { FilterTag } from '@app/shared'

interface props {}

const CatalogTags: React.FC<props> = () => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [categories, setCategories] = useState<Set<ICatalogFiltersCategory[]>>(new Set())
  const { push } = useHistory()
  const { search } = useLocation()

  const { data } = useCatalogFilters()

  const getCategories = (arr?: ICatalogFiltersCategory[]) => {
    if (arr) {
      arr.map((category) => {
        if (category.children) {
          setCategories((prevState: any) => new Set([...prevState, ...category.children]))
          getCategories(category.children)
        }

        setCategories((prevState: any) => new Set([...prevState, category]))
      })
    }
  }

  useEffect(() => {
    getCategories(data?.categories)
  }, [data])

  useEffect(() => {
    setSelectedFilters(
      getQueryParams(queryKeys.CATEGORIES)
        ? [...getQueryParams(queryKeys.CATEGORIES).split(',')]
        : [],
    )
  }, [search])

  const closeTag = async (key: number | string) => {
    setIsDisabled(true)
    const categories = getQueryParams('categories').split(',')
    const filteredCategories = categories.filter((id) => id !== key.toString())

    await push({
      search: serialize('categories', filteredCategories.join(',')),
    })

    await queryClient
      .fetchQuery<IProductWithPaginationResponse>(CATALOG_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно!')
      })
      .catch(() => {
        message.error('Произошла ошибка! Попробуйте повторить позже')
      })

    setIsDisabled(false)
  }

  return (
    <div className={styles.tags}>
      {[...categories].map(({ title, id }: any) => {
        if (selectedFilters.includes(id.toString())) {
          return (
            <FilterTag key={id} title={title} isDisabled={isDisabled} id={id} handleFunction={closeTag}/>
          )
        }
      })}
    </div>
  )
}

export default React.memo(CatalogTags)
