import React, { useState } from 'react'
import { FileOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { Menu, message, Modal, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { uploadActiveCartApi } from '@api/rest/active-cart'
import { queryClient } from '@app/index'
import { CART_KEY } from '@app/hooks/query/active-cart'

interface props {}

const CartDownload: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const uploadFile = async (file: RcFile) => {
    setIsLoading(true)
    const data = new FormData()

    data.append('file', file)

    return uploadActiveCartApi(data)
      .then(async (response) => {
        if (response.status === 200) {
          await queryClient.invalidateQueries(CART_KEY)
          setIsLoading(false)
          message.success('Корзина успешно загружена')
          hideModal()
          return response.data
        }
      })
      .catch(() => {
        setIsLoading(false)
        message.error('Ошибка! Возможно, вы указали неверный формат файла. ')
      })
  }

  return (
    <React.Fragment>
      <Menu.Item key="3" onClick={showModal} icon={<FileOutlined />}>
        Загрузить из файла
      </Menu.Item>
      <Modal
        title="Загрузить корзину"
        visible={visible}
        okText={'Загрузить'}
        footer={false}
        onCancel={hideModal}
      >
        <Upload.Dragger
          action={uploadFile}
          disabled={isLoading}
          showUploadList={false}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            {isLoading ? <LoadingOutlined /> : <InboxOutlined />}
          </p>
          <p className="ant-upload-text">Нажмите или перенесите файл с корзиной сюда.</p>
          <p className="ant-upload-hint">Вы можете выбрать только один файл с расширением xlsx</p>
        </Upload.Dragger>
      </Modal>
    </React.Fragment>
  )
}

export default CartDownload
