import React from 'react'
import styles from './catalog-categories.module.scss'
import { Collapse } from 'antd'
import { ICatalogFiltersCategory } from '@app/interfaces/filters'
import { CatalogTree } from '..'

const { Panel } = Collapse

interface props {
  categories: ICatalogFiltersCategory[]
}

const CatalogCategories: React.FC<props> = ({ categories }) => {
  return (
    <div className={styles.categories}>
      <Collapse ghost expandIconPosition={'right'}>
        {categories.map((category) => (
          <Panel key={category.id} header={category.title}>
            <CatalogTree childCategories={category.children} />
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default React.memo(CatalogCategories)
