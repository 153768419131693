import { useQuery } from 'react-query'
import { getCatalogFiltersApi } from '@api/rest/filters'
import { AxiosResponse } from 'axios'
import { ICatalogFiltersResponse } from '@app/interfaces/filters'

export const CATALOG_FILTERS_KEY = 'catalog_filters'

export function useCatalogFilters() {
  return useQuery<ICatalogFiltersResponse, Error>(
    CATALOG_FILTERS_KEY,
    () =>
      getCatalogFiltersApi().then(
        (response: AxiosResponse<ICatalogFiltersResponse>) => response.data,
      ),
    {
      refetchOnMount: false,
    },
  )
}
