import React from 'react'
import { NavLink } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import styles from './layout-menu.module.scss'
import menuIcons from '@assets/images/menu.svg'

interface props {
  visible: boolean
  menuHandler: () => void
}

const menuLinks = [
  {
    title: 'Главная',
    path: DashboardRoutes.HOME,
    iconID: 'home',
  },
  {
    title: 'Персональные данные',
    path: DashboardRoutes.PROFILE,
    iconID: 'user',
  },
  {
    title: 'Мои заказы',
    path: DashboardRoutes.ORDERS,
    iconID: 'checklist',
  },
  {
    title: 'Подбор товаров',
    path: DashboardRoutes.CATALOG,
    iconID: 'search-market',
  },
  {
    title: 'Корзина',
    path: DashboardRoutes.CART,
    iconID: 'cart',
  },
  {
    title: 'Отложенные корзины',
    path: DashboardRoutes.SAVED_CARTS,
    iconID: 'save',
  },
  {
    title: 'Избранное',
    path: DashboardRoutes.FAVORITES,
    iconID: 'heart',
  },
  // {
  //   title: 'Финансы',
  //   path: DashboardRoutes.FINANCES,
  //   iconID: 'finances',
  //   divider: true,
  // },
  {
    title: 'Прайс-листы',
    path: DashboardRoutes.PRICE_LISTS,
    iconID: 'price-list',
    divider: true,
  },
  {
    title: 'Документы',
    path: DashboardRoutes.DOCUMENTS,
    iconID: 'docs',
  },
  {
    title: 'Обратная связь',
    path: DashboardRoutes.SUPPORT,
    iconID: 'support',
  },
]

const LayoutMenu: React.FC<props> = ({ visible, menuHandler }) => {
  // Меняем статус меню, если это мобильная версия
  const closeMenuOnMobileDevices = () => {
    return window.screen.width < 768 && menuHandler()
  }

  return (
    <nav className={[styles.navigation, visible ? styles.navShow : ''].join(' ')}>
      <ul className={styles.list}>
        {menuLinks.map(({ path, divider, iconID, title }, index) => (
          <li key={index} className={[styles.item, divider ? styles.divider : ''].join(' ')}>
            <NavLink
              exact={false}
              activeClassName={styles.active}
              className={styles.link}
              to={path}
              onClick={closeMenuOnMobileDevices}
            >
              <svg className={styles.icon} width={20} height={20}>
                <use href={menuIcons + '#' + iconID} />
              </svg>
              <span className={styles.text}>{title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default React.memo(LayoutMenu)
