import { useQuery } from 'react-query'
import { getAllProductsApi } from '@api/rest/catalog'
import { AxiosResponse } from 'axios'
import { IProductWithPaginationResponse} from '@app/interfaces'

export const CATALOG_KEY = 'catalog'

export function useCatalogProducts() {
  return useQuery<IProductWithPaginationResponse, Error>(CATALOG_KEY, () =>
    getAllProductsApi().then((response: AxiosResponse<IProductWithPaginationResponse>) => response.data),
  )
}
