import React from 'react'

interface props {
  quantity: number
}

const CartProductQuantity: React.FC<props> = ({ quantity }) => {
  return (
    <React.Fragment>
      <strong>В корзине: </strong> <span>{quantity} товара</span>
    </React.Fragment>
  )
}

export default CartProductQuantity
