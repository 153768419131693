import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { logoutApi } from '@api/rest/auth'
import { message } from 'antd'
import { AUTHENTICATED } from '@app/constants/auth'
import { queryClient } from '@app/index'

export function useLogout() {
  const { push } = useHistory()

  const { isLoading, mutateAsync } = useMutation(logoutApi, {
    onSuccess: () => {
      localStorage.removeItem(AUTHENTICATED)
      queryClient.removeQueries()
      push({
        pathname: '/auth',
      })
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const logout = () => mutateAsync()

  return { isLoading, logout }
}
