import React from 'react'
import styles from './filter-tag.module.scss'
import { CloseOutlined } from '@ant-design/icons'

interface props {
  id: number | string
  title: string
  isDisabled: boolean
  handleFunction: (id: number | string) => void
}

const FilterTag: React.FC<props> = ({ id, title, isDisabled, handleFunction }) => {
  return (
    <div className={[styles.tag, isDisabled ? styles.disabled : ''].join(' ')}>
      <span>{title}</span>
      <button
        className={styles.btn}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleFunction(id)}
      >
        <CloseOutlined width={9} height={9} className={styles.icon} />
      </button>
    </div>
  )
}

export default FilterTag
