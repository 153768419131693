import {useQuery, UseQueryOptions} from 'react-query'
import { getActiveCartApi } from '@api/rest/active-cart'
import { AxiosResponse } from 'axios'
import { ICart } from '@app/interfaces/cart'

export const CART_KEY = 'cart'

export function useActiveCart(options?: UseQueryOptions<ICart, Error>) {
  return useQuery<ICart, Error>(CART_KEY,
    () => getActiveCartApi().then((response: AxiosResponse<ICart>) => response.data),
    {
      refetchOnMount: false,
      ...options
    },
  )
}
