import React from 'react'
import {
  ActiveCartCounter,
  CartTableTitle,
  Container,
  ErrorBoundary,
  LinkButton,
  PageInfo,
  Pagination,
  ProductListTable,
  SearchInput,
} from '@app/shared'
import styles from './catalog-page.module.scss'
import { CatalogFilters, CatalogTags } from './components'
import { PageHeader, Skeleton, Tabs } from 'antd'
import { CATALOG_KEY, useCatalogProducts } from '@app/hooks/query/catalog'
import { useActiveCart } from '@app/hooks/query/active-cart'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { ICartCounterProps } from '@app/interfaces/cart'

interface props {}

const CatalogPage: React.FC<props> = () => {
  const {
    data: catalogResponse,
    isLoading: isCatalogLoading,
    isError: isCatalogError,
  } = useCatalogProducts()

  const {
    data: activeCartResponse,
    isLoading: isActiveCartLoading,
    isError: isActiveCartError,
  } = useActiveCart()

  if (isCatalogLoading || isActiveCartLoading) return <Skeleton active />

  if (isCatalogError || isActiveCartError) return <ErrorBoundary />

  const { data, ...pagination } = catalogResponse!
  const { items } = activeCartResponse!

  const catalogTitle = () => <CartTableTitle />
  const catalogFooter = () => <Pagination pagination={pagination} fetchingQueryKey={CATALOG_KEY} />
  const counterFunction = (props: ICartCounterProps) => <ActiveCartCounter {...props} />

  return (
    <Container>
      <div className={styles.catalogPage}>
        <PageInfo title={'Каталог товаров'} />
        <PageHeader title={'Каталог товаров'} />
        <div className={styles.input}>
          <SearchInput fetchingQueryKey={CATALOG_KEY} placeholder={'Начните поиск'} />
        </div>
        <CatalogTags />
        <Tabs
          defaultActiveKey={'1'}
          tabBarExtraContent={{
            right: <CatalogFilters />,
          }}
        >
          <Tabs.TabPane key={'1'} tab={'Заказ'}>
            <ProductListTable
              asyncSorting={true}
              invalidateQueryKey={CATALOG_KEY}
              counterFunction={counterFunction}
              tableTitle={catalogTitle}
              tableFooter={catalogFooter}
              data={data}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={'2'} tab={'Корзина'}>
            <ProductListTable
              asyncSorting={false}
              invalidateQueryKey={CATALOG_KEY}
              counterFunction={counterFunction}
              tableTitle={catalogTitle}
              data={items}
            />
          </Tabs.TabPane>
        </Tabs>
        <div className={styles.cartBtn}>
          <LinkButton url={DashboardRoutes.CART}>Перейти в корзину</LinkButton>
        </div>
      </div>
    </Container>
  )
}

export default React.memo(CatalogPage)
