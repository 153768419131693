import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import styles from './download-button.module.scss'

interface props {
  link: string
}

const DownloadButton: React.FC<props> = ({link}) => {
  return (
    <a href={link} className={styles.link} rel={'noopener noreferrer'}>
      <DownloadOutlined />
    </a>
  )
}

export default React.memo(DownloadButton)
