import React from 'react'
import { Col, Row, Typography } from 'antd'
import styles from './cart-info.module.scss'
import { ICartInfo } from '@app/interfaces/cart'
import { CartProductQuantity, CartTotal, CartVolume, CartWeight } from '@app/shared'

interface props {
  cartInfo: ICartInfo
  children?: React.ReactNode
}

const CartInfo: React.FC<props> = ({ cartInfo, children }) => {
  const { weight, quantity, volume, total } = cartInfo

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <Row gutter={[20, 20]}>
          <Col xs={12}>
            <CartWeight weight={weight} />
          </Col>
          <Col xs={12}>
            <CartProductQuantity quantity={quantity} />
          </Col>
          <Col xs={12}>
            <CartVolume volume={volume} />
          </Col>
          <Col xs={12}>
            <CartTotal total={total} />
          </Col>
        </Row>
        {cartInfo.total < 5000 && (
          <React.Fragment>
            <br />
            <Typography.Text type={'danger'}>
              <strong>Минимальная сумма заказа</strong> 5 000 руб.
            </Typography.Text>
          </React.Fragment>
        )}
      </div>
      <div className={styles.linkWrapper}>{children}</div>
    </div>
  )
}

export default CartInfo
