import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { OrderCard } from './components'
import { PageHeader, Skeleton } from 'antd'
import { useOrder } from '@app/hooks/query/order'
import { useParams } from 'react-router-dom'
import { getOrderTitle } from '@app/utils/getOrderTitle'

interface props {}

const OrderPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useOrder(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const title = getOrderTitle(data?.number, data?.created_at)

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Container>
        <PageHeader
          title={title}
          //extra={[<OrderActions orderId={id} key={'1'} />]}
        />
        <OrderCard order={data!} />
      </Container>
    </React.Fragment>
  )
}

export default OrderPage
