import React from 'react'
import styles from './container.module.scss'

interface props {
  className?: string
}

const Container: React.FC<props> = ({ children, className }) => {
  return <div className={[styles.container, className].join(' ')}>{children}</div>
}

export default Container
