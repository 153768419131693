import React from 'react'
import styles from './dropdown-icon.module.scss'
import {EllipsisOutlined} from "@ant-design/icons"

interface props {
}

const DropdownIcon: React.FC<props> = () => {

	return (
		<EllipsisOutlined className={styles.icon}/>
	)
}

export default DropdownIcon
