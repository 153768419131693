import * as queryString from 'query-string'
import { history } from '@api/history'
import {IQueryParams} from "@app/interfaces"


export function pickQueryParams(...params: Array<keyof IQueryParams>) {
  const queryParams: IQueryParams = queryString.parse(history.location.search)

  return Object.assign(
    {},
    ...Object.entries(queryParams)
      .filter(([key]) => params.includes(key as keyof IQueryParams))
      .map(([key, value]) => ({ [key]: value })),
  )
}
