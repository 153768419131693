import React from 'react'
import styles from './product-in-stock.module.scss'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { NoDataText } from '@app/shared'

interface props {
  inStock?: number
  unit?: string
}

const ProductInStock: React.FC<props> = ({ inStock, unit }) => {
  const renderIcon = () => {
    if (!inStock) return <NoDataText />

    if (inStock <= 0) {
      return <CloseOutlined className={styles.notInStock} />
    } else if (inStock > 0 && inStock <= 50) {
      return <CheckOutlined className={styles.aFew} />
    }
    return <CheckOutlined className={styles.inStock} />
  }

  return (
    <div className={styles.wrapper}>
      {renderIcon()}
      {inStock && unit ? (
        <span className={styles.text}>
          {inStock} {unit}.
        </span>
      ) : null}
    </div>
  )
}

export default React.memo(ProductInStock)
