import React from 'react'
import { Container, PageInfo } from '@app/shared'
import { PageHeader, Tabs } from 'antd'
import { DocumentAccounts, DocumentReconciliation, DocumentsFiltersForm } from './components'
import styles from './documents-page.module.scss'
import { useHistory, useLocation } from 'react-router-dom'

interface props {}

const DocumentsPage: React.FC<props> = () => {
  const { push } = useHistory()
  const location = useLocation()
  const handleChangeTab = () => {
    push(location.pathname)
  }

  return (
    <div className={styles.documentsPage}>
      <PageInfo title={'Документы'} />
      <Container>
        <PageHeader title={'Документы'} />
        <Tabs defaultActiveKey={'1'} destroyInactiveTabPane onChange={handleChangeTab}>
          <Tabs.TabPane key={'1'} tab={'Счета'}>
            <DocumentsFiltersForm buttonText={'Применить'} />
            <DocumentAccounts />
          </Tabs.TabPane>
          <Tabs.TabPane key={'2'} tab={'Акт сверки'}>
            <DocumentsFiltersForm buttonText={'Запросить акт'} />
            <DocumentReconciliation />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </div>
  )
}

export default DocumentsPage
