import React from 'react'
import { Tabs, Typography } from 'antd'
import { MessageForm } from '@app/shared'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

interface props {}

const SupportTabs: React.FC<props> = () => {
  return (
    <Tabs
      tabBarExtraContent={{
        right: [
          <Link key={'1'} to={'/support/history'}>
            История обращений
          </Link>,
        ],
      }}
    >
      <TabPane key={'1'} tab={'Сообщение менеджеру / ассистенту'}>
        <Typography.Paragraph>
          Отправьте сообщение менеджеру и он свяжется с вами в ближайшее время.
        </Typography.Paragraph>
        <MessageForm subject={'Сообщение менеджеру / ассистенту'} />
      </TabPane>
      <TabPane key={'2'} tab={'Технические вопросы'}>
        <Typography.Paragraph>
          Если у вас возникли технические вопросы, отправьте нам сообщение и наши специалисты вас
          проконсультируют.
        </Typography.Paragraph>
        <MessageForm subject={'Технические вопросы'} />
      </TabPane>
      <TabPane key={'3'} tab={'Предложение по улучшению ЛК'}>
        <MessageForm subject={'Предложение по улучшению ЛК'} />
      </TabPane>
    </Tabs>
  )
}

export default SupportTabs
