import React, { useEffect, useState } from 'react'
import { message, Space } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { queryClient } from '@app/index'
import { DOCUMENTS_KEY, useDocumentsFilters } from '@app/hooks/query/documents'
import { FilterTag } from '@app/shared'
import { IQueryParams } from '@app/interfaces'
import { pickQueryParams } from '@app/utils/pickQueryParams'

interface props {}

const DocumentsTags: React.FC<props> = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [queries, setQueries] = useState<IQueryParams>({})
  const { push } = useHistory()
  const { search } = useLocation()

  useEffect(() => {
    const pickedQueryParams = pickQueryParams('type_id', 'start_date', 'end_date')
    setQueries(pickedQueryParams)
  }, [search])

  const { data, isLoading } = useDocumentsFilters()
  if (isLoading) return <React.Fragment />

  const deleteFilter = async (key: string | number) => {
    setIsDisabled(true)
    const newQueries = { ...queries, [key]: '' }

    await push({
      search: queryString.stringify(newQueries),
    })

    await queryClient
      .fetchQuery(DOCUMENTS_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно!')
      })
      .catch(() => {
        message.error('Прозиошла ошибка! Что-то пошло не так')
      })

    setIsDisabled(false)
  }

  return (
    <Space>
      {Object.entries(queries)
        .filter(([_, value]) => value)
        .map(([key, value]) => {
          return (
            <FilterTag
              id={key}
              key={key}
              title={
                key === 'type_id'
                  ? data!.document_types.find((type) => type.id.toString() === value)!.title
                  : value
              }
              isDisabled={isDisabled}
              handleFunction={deleteFilter}
            />
          )
        })}
    </Space>
  )
}

export default DocumentsTags
