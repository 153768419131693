import React from 'react'
import { useMutation } from 'react-query'
import { setActiveCartApi } from '@api/rest/active-cart'
import { Button, message } from 'antd'
import styles from '@app/pages/saved-carts-page/components/change-cart-status-button/change-cart-status-button.module.scss'
import { useHistory } from 'react-router-dom'

export interface CheckoutSavedCartButtonProps {
  id: number
  active: boolean
}

const CheckoutSavedCartButton: React.FC<CheckoutSavedCartButtonProps> = ({ id, active }) => {
  const { push } = useHistory()
  const { isLoading, mutateAsync } = useMutation(setActiveCartApi, {
    onSuccess: async () => {
      message.success('Активная корзина успешно изменена')
      push('/cart')
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте, пожалуйста, позже')
    },
  })

  const changeStatusHandler = async () => {
    active ? push('/cart') : await mutateAsync(id)
  }

  return (
    <Button
      size={'large'}
      className={styles.changeStatusButton}
      type={'primary'}
      disabled={isLoading}
      loading={isLoading}
      onClick={changeStatusHandler}
    >
      Оформить заказ
    </Button>
  )
}

export default CheckoutSavedCartButton
