import React, { useState } from 'react'
import { FilterButton } from '@app/shared'
import { Drawer } from 'antd'
import { FinancesFiltersForm } from '@app/pages/finances-page/components'

interface props {}

const FinancesFilters: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false)

  const showDrawer = () => setVisible(true)
  const hideDrawer = () => setVisible(false)

  return (
    <React.Fragment>
      <FilterButton onClick={showDrawer} text={'Все фильтры'} />
      <Drawer visible={visible} title={'Параметры'} closable={true} onClose={hideDrawer}>
        <FinancesFiltersForm hideDrawer={hideDrawer} />
      </Drawer>
    </React.Fragment>
  )
}

export default React.memo(FinancesFilters)
