import React from 'react'
import { Button, Dropdown, Menu, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { DropdownIcon } from '@app/shared'
import styles from './saved-cart-dropdown.module.scss'
import { useMutation } from 'react-query'
import { deleteSavedCartApi } from '@api/rest/carts'
import { queryClient } from '@app/index'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'
import { ICart } from '@app/interfaces/cart'

interface props {
  cartId: number
}

const SavedCartDropdown: React.FC<props> = ({ cartId }) => {
  const { isLoading, mutateAsync } = useMutation(deleteSavedCartApi, {
    onSuccess: () => {
      const oldData = queryClient.getQueryData<ICart[]>(ALL_CARTS_KEY)
      queryClient.setQueryData(
        ALL_CARTS_KEY,
        oldData?.filter((cart) => cart.id !== cartId),
      )

      message.success('Корзина успешно удалена')
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const deleteHandler = () => mutateAsync(cartId)

  const menu = (
    <Menu>
      <Menu.Item
        key={'1'}
        disabled={isLoading}
        onClick={deleteHandler}
        icon={<DeleteOutlined />}
        className={styles.delete}
      >
        Удалить корзину
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button className={styles.btn} size={'small'} type={'default'} shape={'circle'}>
        <DropdownIcon />
      </Button>
    </Dropdown>
  )
}

export default React.memo(SavedCartDropdown)
