import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import styles from './price-lists-page.module.scss'
import { PriceListsSection } from './components'
import { useCatalogFilters } from '@app/hooks/query/filters'

interface props {}

const PriceListsPage: React.FC<props> = () => {
  const { data, isLoading, isError } = useCatalogFilters()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Прайс-листы'} />
      <Container>
        <PageHeader title={'Прайс-листы'} />

        <div className={styles.container}>
          <h3 className={styles.title}>Порядок действий:</h3>
          <p className={styles.text}>
            Для скачивания прайс-листа на локальный компьютер: (спойлер) выберите и откройте
            соответствующую товарную категорию прайс-листа;
          </p>
          <p className={styles.text}>Нажмите на кнопку для скачивания прайс-листа</p>
        </div>
        <PriceListsSection categories={data!.categories} />
      </Container>
    </React.Fragment>
  )
}

export default PriceListsPage
