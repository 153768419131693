import React, { useContext, useState } from 'react'
import { Descriptions, Divider, Select } from 'antd'
import styles from './profile-organizations.module.scss'
import { IUserOrganization } from '@app/interfaces/user'
import { ProfileAddresses } from '@app/pages/profile-page/components'
import { UserOrganizationContext } from '@app/contexts/userOrganizationContext'

interface props {
  organizations: IUserOrganization[]
}

const ProfileOrganizations: React.FC<props> = ({ organizations }) => {
  const { organization } = useContext(UserOrganizationContext)
  const [selectedOrg, setSelectedOrg] = useState<IUserOrganization | undefined>(organization)

  const setOrganization = (selectedId: number) => {
    setSelectedOrg(organizations.find((org) => org.id === selectedId)!)
  }

  return (
    <React.Fragment>
      <Select
        defaultValue={selectedOrg?.id}
        size={'large'}
        placeholder={'Выберите организацию'}
        className={styles.select}
        onChange={setOrganization}
      >
        {organizations.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>

      {selectedOrg && (
        <React.Fragment>
          <Descriptions
            title={'Общая информация'}
            bordered={true}
            column={1}
            layout={window.screen.width > 576 ? 'horizontal' : 'vertical'}
          >
            <Descriptions.Item label={'Название компании'}>{selectedOrg?.title}</Descriptions.Item>
            <Descriptions.Item label={'ИНН'}>{selectedOrg?.inn}</Descriptions.Item>
            <Descriptions.Item label={'Адрес'}>{selectedOrg?.address}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <ProfileAddresses addresses={selectedOrg.addresses} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ProfileOrganizations
