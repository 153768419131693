import React, { useState } from 'react'
import styles from './orders-filters.module.scss'
import { Button, DatePicker, Drawer, Form, Input, message, Select, Space } from 'antd'
import { FilterButton, MutedButton } from '@app/shared'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { queryClient } from '@app/index'
import { ORDERS_KEY } from '@app/hooks/query/order'
import { IQueryParams } from '@app/interfaces'

const { Option } = Select

interface props {}

const OrdersFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { push, location } = useHistory()
  const { data } = useAuthUserInformation()
  const [visible, setVisible] = useState<boolean>(false)

  const showDrawer = () => setVisible(true)
  const closeDrawer = () => setVisible(false)

  const onFinish = async (values: any) => {
    setIsLoading(true)
    let query: IQueryParams = values

    if (values.created_at) {
      query = { ...values, created_at: moment(values.created_at).format('DD-MM-YYYY') }
    }

    await push({
      search: queryString.stringify(query),
    })

    await queryClient
      .invalidateQueries(ORDERS_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно')
        setVisible(false)
      })
      .catch(() => {
        message.error('Ошибка! Что-то пошло не так')
      })

    setIsLoading(false)
  }

  const { organization_id, id }: IQueryParams = queryString.parse(location.search)

  return (
    <React.Fragment>
      <FilterButton onClick={showDrawer} text={'Фильтр заказа'} />
      <Drawer title="Фильтр" closable={true} visible={visible} onClose={closeDrawer}>
        <Form
          onFinish={onFinish}
          layout={'vertical'}
          className={styles.form}
          initialValues={{ organization_id, id }}
        >
          <Form.Item name={'organization_id'} label={'Оформление заказа на:'}>
            <Select
              className={styles.select}
              size={'large'}
              placeholder={'Выберите организацию'}
              allowClear={true}
            >
              {data?.holding.organizations.map((organization) => (
                <Option value={organization.id.toString()} key={organization.id}>
                  {organization.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'created_at'} label={'Дата заказа:'}>
            <DatePicker
              disabledDate={(date) => date > moment()}
              className={styles.input}
              size={'large'}
              allowClear={true}
              format={'DD-MM-YYYY'}
            />
          </Form.Item>
          <Form.Item name={'id'} label={'Номер заказа:'}>
            <Input placeholder={'Поиск по номеру заказа'} className={styles.input} size={'large'} />
          </Form.Item>
          <div className={styles.buttons}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} disabled={isLoading} loading={isLoading}>
                Применить
              </Button>
              <MutedButton type={'reset'} disabled={isLoading}>
                Сбросить
              </MutedButton>
            </Space>
          </div>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default React.memo(OrdersFilters)
