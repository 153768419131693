import React from 'react'
import { CheckOutlined } from '@ant-design/icons'

interface props {}

const CheckIcon: React.FC<props> = () => {
	const style = {color: '#46af65'}
	
	return <CheckOutlined style={style} />
}

export default CheckIcon
