import React from 'react'
import { Space, Table } from 'antd'
import { CanceledIcon, CheckIcon, DownloadButton, NoDataText, TableMobileTitle } from '@app/shared'
import { priceFormatter } from '@app/utils/priceFormatter'
import { IDocument, IInvoice } from '@app/interfaces/documents'

interface props {
  tableTitle: () => JSX.Element
  tableFooter: () => JSX.Element
  documents: IDocument[]
}

const FinancesTable: React.FC<props> = ({ tableTitle, tableFooter, documents }) => {
  const columns = [
    {
      title: 'Номер заказ',
      dataIndex: 'document_id',
      render: (text: number) => (
        <React.Fragment>
          <TableMobileTitle title={'Номер заказа'} />№ {text}
        </React.Fragment>
      ),
    },
    {
      title: 'Номер счета',
      dataIndex: 'number',
      render: (id: number) => (
        <React.Fragment>
          <TableMobileTitle title={'Номер счета'} />
          Счет № {id}
        </React.Fragment>
      ),
    },
    {
      title: 'Дата',
      dataIndex: 'created_at',
      render: (date: string) => (
        <React.Fragment>
          <TableMobileTitle title={'Дата'} />
          {new Date(date).toLocaleDateString()}
        </React.Fragment>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'invoice_parameter',
      render: (invoice: IInvoice) => (
        <React.Fragment>
          <TableMobileTitle title={'Сумма'} />
          {invoice ? <strong>{priceFormatter(invoice.total)} руб.</strong> : <NoDataText />}
        </React.Fragment>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'invoice_parameter',
      render: (invoice: IInvoice) => (
        <React.Fragment>
          <TableMobileTitle title={'Статус'} />
          {invoice ? (
            <Space>
              {invoice.status.status === 'paid' ? <CheckIcon /> : <CanceledIcon />}
              {invoice.status.title}
            </Space>
          ) : (
            <NoDataText />
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Скачать',
      key: 'download',
      render: (record: IDocument) => (
        <React.Fragment>
          <TableMobileTitle title={'Скачать'} />
          <DownloadButton link={record.link} />
        </React.Fragment>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      rowKey={'id'}
      pagination={false}
      scroll={{ x: true }}
      dataSource={documents}
      title={tableTitle}
      footer={tableFooter}
    />
  )
}

export default FinancesTable
