import React from 'react'
import styles from './filters-container.module.scss'

interface props {
}

const FiltersContainer: React.FC<props> = ({children}) => {
	return (
		<div className={styles.container}>
			{children}
		</div>
	)
}

export default React.memo(FiltersContainer)
