import React, { useState } from 'react'
import styles from './restore-password-form.module.scss'
import { Button, Form, Input, message, Modal, Result } from 'antd'
import { useMutation } from 'react-query'
import { forgotPassword } from '@api/rest/auth'

export interface ForgotPasswordProps {}

const RestorePasswordForm: React.FC<ForgotPasswordProps> = () => {
  const [open, setOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const handleChangeOpen = () => setOpen(!open)

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onSuccess: () => {
      setIsSuccess(true)
    },
    onError: () => {
      message.error('Произошла ошибка! Повторите попытку позже, либо обратитесь в службу поддержки')
    },
  })
  const onFinish = (data: { email: string }) => mutate(data.email)

  return (
    <>
      <button type={'button'} className={styles.forgot} onClick={handleChangeOpen}>
        Забыли пароль?
      </button>
      <Modal
        title={'Восстановление пароля'}
        visible={open}
        destroyOnClose
        onCancel={handleChangeOpen}
        footer={false}
      >
        {isSuccess ? (
          <Result
            className={styles.result}
            status={'success'}
            title={'Запрос успешно отправлен!'}
            subTitle={
              'Ваш запрос на восстановление пароля был успешно передан менеджеру. В ближайшее время он с вами свяжется!'
            }
          />
        ) : (
          <Form onFinish={onFinish}>
            <Form.Item name={'email'} rules={[{ required: true }]}>
              <Input
                autoFocus
                className={styles.input}
                size={'large'}
                placeholder={'Введите ваш логин'}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType={'submit'}
                type={'primary'}
                block
                loading={isLoading}
                disabled={isLoading}
              >
                Отправить
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}

export default RestorePasswordForm
