import React, { useState } from 'react'
import { IAdjustmentProduct, IProduct } from '@app/interfaces'
import { Button, Divider, message, Table } from 'antd'
import { ProductNameWithImage, TableMobileTitle } from '@app/shared'
import { priceFormatter } from '@app/utils/priceFormatter'
import {
  OrderAdjustmentCounter,
  OrderAdjustmentRemove,
} from '@app/pages/order-adjustment-page/components'
import { useMutation } from 'react-query'
import { adjustmentOrderApi } from '@api/rest/orders'
import { queryClient } from '@app/index'
import { ORDERS_KEY } from '@app/hooks/query/order'
import { useHistory } from 'react-router-dom'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import styles from './order-adjustment-form.module.scss'

interface props {
  items: IProduct[]
  orderId: number
}

const OrderAdjustmentForm: React.FC<props> = ({ items, orderId }) => {
  const { push } = useHistory()
  const [products, setProducts] = useState<IProduct[]>(items)

  const { mutateAsync, isLoading } = useMutation(adjustmentOrderApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ORDERS_KEY, orderId])
      message.success('Заказ успешно скорректирован')
      goBackFunction()
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const columns = [
    {
      title: 'Наименование',
      key: 'name',
      render: ({ title, sku, brand, tags, image_path }: IProduct) => (
        <ProductNameWithImage
          promotags={tags}
          title={title}
          imagePath={image_path}
          sku={sku}
          brand={brand?.title}
        />
      ),
    },
    {
      title: 'Цена за шт.',
      dataIndex: 'price',
      render: (price: number) => (
        <React.Fragment>
          <TableMobileTitle title={'Цена за шт.'} />
          <strong>{priceFormatter(price)} руб.</strong>
        </React.Fragment>
      ),
    },
    {
      title: 'Количество',
      key: 'quantity',
      render: ({ quantity, stock, realtime_in_stock, id }: IProduct) => (
        <OrderAdjustmentCounter
          maxInStock={realtime_in_stock}
          quantity={quantity}
          productId={id}
          stock={stock?.stock}
          changeProductQuantity={changeProductQuantity}
        />
      ),
    },
    {
      title: '',
      key: 'remove',
      render: (record: IProduct) => (
        <OrderAdjustmentRemove productId={record.id} removeProduct={removeProduct} />
      ),
    },
  ]

  const changeProductQuantity = (newQuantityValue: number, productId: number) => {
    const productWithNewQuantityValue = products.find((product) => product.id === productId)!
    productWithNewQuantityValue.quantity = newQuantityValue

    setProducts(
      products.map((product) => (product.id === productId ? productWithNewQuantityValue : product)),
    )
  }

  const removeProduct = (productId: number) => {
    setProducts(products.filter((product) => product.id !== productId))
  }

  const tableTitle = () => <span>Список товаров в заказе</span>

  const submit = () => {
    const order_items: IAdjustmentProduct[] = products.map(({ id, quantity, price }) => ({
      product_id: id,
      quantity,
      price,
    }))

    return mutateAsync({
      id: orderId,
      order_items,
    })
  }

  const goBackFunction = () => push(DashboardRoutes.ORDERS + '/' + orderId)

  return (
    <React.Fragment>
      <Table
        columns={columns}
        rowKey={'id'}
        scroll={{ x: true }}
        pagination={false}
        title={tableTitle}
        dataSource={products}
      />
      <br />
      <div className={styles.buttonsWrapper}>
        <Button
          type={'primary'}
          icon={<SaveOutlined />}
          onClick={submit}
          disabled={isLoading}
          loading={isLoading}
        >
          Принять изменения
        </Button>
        <Divider type={'vertical'} />
        <Button
          disabled={isLoading}
          type={'default'}
          icon={<CloseOutlined />}
          onClick={goBackFunction}
        >
          Отменить изменения
        </Button>
      </div>
    </React.Fragment>
  )
}

export default OrderAdjustmentForm
