import {useQuery} from "react-query"
import {getAuthUserInformationApi} from "@api/rest/auth"
import {AxiosResponse} from "axios"
import {IUserInformation} from "@app/interfaces/user"

export const AUTH_KEY = 'auth'

export function useAuthUserInformation() {
	return useQuery<IUserInformation, Error>(AUTH_KEY, () => getAuthUserInformationApi().then((response:AxiosResponse<IUserInformation>) => response.data), {
		refetchOnMount: false,
		retry: false
	})
}
