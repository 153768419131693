import React from 'react'
import styles from './cart-table-title.module.scss'
import { Col, Row, Spin } from 'antd'
import { useActiveCart } from '@app/hooks/query/active-cart'
import { CartTotal, CartVolume, CartWeight } from '@app/shared'

interface props {}

const CartTableTitle: React.FC<props> = () => {
  const { data, isLoading } = useActiveCart()

  if (isLoading) return <Spin />

  const { weight, volume, total } = data!

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={8}>
        Список товаров
      </Col>
      <Col xs={24} md={16}>
        <div className={styles.summary}>
          <div className={styles.block}>
            <CartTotal total={total} />
          </div>
          <div className={styles.block}>
            <CartWeight weight={weight} />
          </div>
          <div className={styles.block}>
            <CartVolume volume={volume} />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default React.memo(CartTableTitle)
