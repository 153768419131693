import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import styles from './product-quantity.module.scss'
import { LoadingOutlined } from '@ant-design/icons'

interface props {
  quantity: number
  isLoading?: boolean
  inStock?: number
  handler: Function
  maxInStock: number
}

const ProductQuantity: React.FC<props> = ({
  isLoading,
  maxInStock,
  inStock,
  quantity,
  handler,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      quantity,
    })
  }, [form, quantity])

  const handleQuantityChange = (key: 'decrement' | 'increment' | 'input', value?: string) => {
    const quantityValue = form.getFieldValue('quantity')

    switch (key) {
      case 'decrement':
        form.setFieldsValue({
          quantity: Math.max(0, quantityValue - 1),
        })
        break
      case 'increment':
        form.setFieldsValue({
          quantity: Math.min(maxInStock, quantityValue + 1),
        })
        break
      case 'input':
        form.setFieldsValue({
          quantity: !Number(value) ? 1 : Math.min(maxInStock, Number(value)),
        })
        break
    }

    handler(form.getFieldValue('quantity'))
  }

  return (
    <Form initialValues={{ quantity }} form={form}>
      {!inStock ? (
        'Нет в наличии'
      ) : (
        <div className={styles.counter}>
          {isLoading ? (
            <LoadingOutlined className={styles.icon} />
          ) : (
            <React.Fragment>
              <button
                type={'button'}
                onClick={() => handleQuantityChange('decrement')}
                className={styles.decrement}
              >
                -
              </button>
              <Form.Item name={'quantity'} label={''} className={styles.reset}>
                <Input
                  id={Math.random().toString()}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                    handleQuantityChange('input', value)
                  }
                  className={styles.input}
                />
              </Form.Item>
              <button
                type={'button'}
                onClick={() => handleQuantityChange('increment')}
                className={styles.increment}
              >
                +
              </button>
            </React.Fragment>
          )}
        </div>
      )}
    </Form>
  )
}

export default React.memo(ProductQuantity)
