import React from 'react'
import styles from './product-list-table.module.scss'
import { IParams, IProduct, IStock } from '@app/interfaces'
import {
  NoDataText,
  PriceWithTitle,
  ProductInStock,
  ProductNameWithImage,
  TableMobileTitle,
} from '@app/shared'
import { Table, TableColumnsType } from 'antd'
import { ICartCounterProps } from '@app/interfaces/cart'
import { useHistory, useLocation } from 'react-router-dom'
import { serialize } from '@app/utils/serializeQuery'
import { SorterResult } from 'antd/es/table/interface'
import { queryClient } from '@app/index'

interface props {
  invalidateQueryKey: string
  data: IProduct[]
  tableTitle?: () => JSX.Element
  tableFooter?: () => JSX.Element
  removeProductFunction?: (productId: number) => JSX.Element
  counterFunction: (data: ICartCounterProps) => JSX.Element
  asyncSorting: boolean
}

const ProductListTable: React.FC<props> = ({
  invalidateQueryKey,
  data,
  tableTitle,
  tableFooter,
  removeProductFunction,
  counterFunction,
  asyncSorting,
}) => {
  const { replace } = useHistory()
  const { pathname } = useLocation()
  const columns: TableColumnsType<IProduct> = [
    {
      title: 'Наименование',
      key: 'title',
      sorter: asyncSorting ? true : (a, b) => a.title.length - b.title.length,
      render: ({ title, sku, image_path, tags, brand }: IProduct) => (
        <ProductNameWithImage
          promotags={tags}
          title={title}
          sku={sku}
          imagePath={image_path}
          brand={brand?.title}
        />
      ),
    },
    {
      title: 'Наличие',
      dataIndex: 'stock',
      render: ({ stock }: IStock, { unit }: IProduct) => (
        <React.Fragment>
          <TableMobileTitle title={'Наличие'} />
          <ProductInStock inStock={stock} unit={unit?.title} />
        </React.Fragment>
      ),
    },
    {
      title: 'Цена за 1 шт.',
      dataIndex: 'price',
      key: 'price',
      sorter: asyncSorting ? true : (a, b) => a.price - b.price,
      render: (price: number) => <PriceWithTitle title={'Цена за 1 шт.'} sum={price} />,
    },
    {
      title: 'В упаковке',
      dataIndex: 'params',
      render: (params: IParams, { unit }: IProduct) => (
        <React.Fragment>
          <TableMobileTitle title={'В упаковке'} />
          {params ? (
            <span>
              {params.package_quantity} {unit?.title}
            </span>
          ) : (
            <NoDataText />
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Количество в корзине',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity: number, { stock, id, realtime_in_stock }: IProduct) =>
        counterFunction({
          productId: id,
          inStock: stock?.stock,
          quantity,
          maxInStock: realtime_in_stock,
        }),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      render: (sum: number) => <PriceWithTitle title={'Сумма'} sum={sum} />,
    },
    {
      key: 'actions',
      className: !removeProductFunction ? styles.hide : undefined,
      render: (record: IProduct) => (removeProductFunction ? removeProductFunction(record.id) : ''),
    },
  ]

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        rowKey={'id'}
        showHeader={true}
        title={tableTitle || undefined}
        footer={tableFooter || undefined}
        pagination={false}
        dataSource={data}
        scroll={{ x: true }}
        showSorterTooltip={false}
        onChange={(
          pagination,
          filters,
          sorter: SorterResult<IProduct> | SorterResult<IProduct>[],
        ) => {
          if (!Array.isArray(sorter) && asyncSorting) {
            replace({
              pathname,
              search: serialize([
                ['sort', sorter.columnKey as string],
                ['direction', sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''],
              ]),
            })
            queryClient.invalidateQueries(invalidateQueryKey)
          }
        }}
      />
    </div>
  )
}

export default ProductListTable
