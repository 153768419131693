import axios from 'axios'
import { AUTHENTICATED } from '@app/constants/auth'
import env from '@sanchik97/react-dotenv-public-url'

const makeRequest = axios.create({
  baseURL: env.SHOP_URL,
  params: '',
  withCredentials: true,
})

makeRequest.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

makeRequest.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== '/auth') {
      localStorage.removeItem(AUTHENTICATED)
      window.location.href = '/auth'
    }
    return Promise.reject(error)
  },
)

export default makeRequest
