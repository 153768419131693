import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import { ConfigProvider } from 'antd'
import Routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import ru_RU from 'antd/es/locale/ru_RU'
import ScrollRestoration from 'react-scroll-restoration'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { history } from '@api/history'
import 'moment/locale/ru'
import { UserOrganizationProvider } from '@app/contexts/userOrganizationContext'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <QueryClientProvider client={queryClient}>
      <UserOrganizationProvider>
        <Router history={history}>
          <ScrollRestoration />
          <Routes />
        </Router>
      </UserOrganizationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
