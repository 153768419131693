import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { Alert, PageHeader, Skeleton } from 'antd'
import styles from './cart-page.module.scss'
import { useActiveCart } from '@app/hooks/query/active-cart'
import { CartCheckout } from '@app/pages/cart-page/components'
import { useAuthUserInformation } from '@app/hooks/query/auth'

interface props {}

const CartPage: React.FC<props> = () => {
  const {
    data: userData,
    isLoading: isAuthUserLoading,
    isError: isAuthUserError,
  } = useAuthUserInformation()
  const { data, isLoading, isError } = useActiveCart({ refetchOnMount: true })

  if (isAuthUserLoading || isLoading) return <Skeleton active />
  if (isAuthUserError || isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Корзина'} />
      <Container>
        <PageHeader title={'Корзина'} />
        <h3 className={styles.info}>Внимание! Остатки остаются актуальными в течение пяти минут</h3>
        {!data?.items.length ? (
          <Alert
            message={'Ваша корзина пуста'}
            description={
              'Перед оформлением заказа необходимо добавить один, либо несколько товаров в корзину.'
            }
            showIcon={true}
            type={'info'}
          />
        ) : (
          <CartCheckout cart={data!} userOrganizations={userData!.holding.organizations} />
        )}
      </Container>
    </React.Fragment>
  )
}

export default CartPage
