import React, { useContext } from 'react'
import { ActiveCartCounter, CartInfo, ProductListTable, RemoveFromCart } from '@app/shared'
import { CartDropdown, SelectOrganization } from '@app/pages/cart-page/components'
import { ICart, ICartCounterProps } from '@app/interfaces/cart'
import { Button, Col, message, Row } from 'antd'
import { getActiveCartApi } from '@api/rest/active-cart'
import { IUserOrganization } from '@app/interfaces/user'
import { useHistory } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { useMutation } from 'react-query'
import { queryClient } from '@app/index'
import { CART_KEY } from '@app/hooks/query/active-cart'
import { AxiosResponse } from 'axios'
import { UserOrganizationContext } from '@app/contexts/userOrganizationContext'

interface props {
  cart: ICart
  userOrganizations: IUserOrganization[]
}

const CartCheckout: React.FC<props> = ({ cart, userOrganizations }) => {
  const { organization, setOrganization } = useContext(UserOrganizationContext)
  const { isLoading, mutateAsync } = useMutation(getActiveCartApi, {
    onSuccess: (response: AxiosResponse<ICart>) => {
      message.success('Организация успешно изменена')
      queryClient.setQueryData(CART_KEY, response.data)
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const { push } = useHistory()

  const { items, ...cartInfo } = cart

  const changeOrganization = async (value: number) => {
    await mutateAsync().then(() => setOrganization(value))
  }

  const handleCheckout = () =>
    push({
      pathname: DashboardRoutes.CHECKOUT,
      state: {
        organizationId: organization?.id,
      },
    })

  const tableTitle = () => <React.Fragment>Ваш заказ</React.Fragment>
  const tableFooter = () => (
    <CartInfo cartInfo={cartInfo}>
      <Button
        disabled={!organization?.id || isLoading || cartInfo.total < 5000}
        type={'primary'}
        onClick={handleCheckout}
        block
      >
        Оформить заказ
      </Button>
    </CartInfo>
  )

  const counterFunction = (props: ICartCounterProps) => <ActiveCartCounter {...props} />
  const removeProductFunction = (productId: number) => <RemoveFromCart productId={productId} />

  return (
    <React.Fragment>
      <Row
        style={{ marginBottom: 15 }}
        gutter={[16, 16]}
        justify={'space-between'}
        align={'middle'}
      >
        <Col>
          <SelectOrganization
            changeOrganization={changeOrganization}
            organizationId={organization?.id}
            isLoading={isLoading}
            userOrganizations={userOrganizations}
          />
        </Col>
        <Col style={{ paddingLeft: 20 }}>
          <CartDropdown cartId={cart.id} />
        </Col>
      </Row>
      <ProductListTable
        asyncSorting={false}
        invalidateQueryKey={CART_KEY}
        counterFunction={counterFunction}
        removeProductFunction={removeProductFunction}
        data={items}
        tableTitle={tableTitle}
        tableFooter={tableFooter}
      />
    </React.Fragment>
  )
}

export default CartCheckout
