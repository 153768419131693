import React, { useEffect, useMemo, useState } from 'react'
import { ICatalogFiltersCategory } from '@app/interfaces/filters'
import { message, Tree } from 'antd'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { serialize } from '@app/utils/serializeQuery'
import { getQueryParams } from '@app/utils/getQueryParams'
import { queryKeys } from '@app/constants/query-keys'
import { queryClient } from '@app/index'
import { IProductWithPaginationResponse } from '@app/interfaces'
import { CATALOG_KEY } from '@app/hooks/query/catalog'
import { getCategoriesKey } from '@app/utils/getCategoriesKey'

interface props {
  childCategories: ICatalogFiltersCategory[]
}

const CatalogTree: React.FC<props> = ({ childCategories }) => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const { push } = useHistory()
  const { search } = useLocation()

  useEffect(() => {
    const categoriesQuery = getQueryParams(queryKeys.CATEGORIES)

    if (categoriesQuery) {
      const keys = getCategoriesKey(childCategories)
      setCheckedKeys(categoriesQuery.split(',').filter((key: string) => keys.includes(key)))
    }
  }, [search])

  const onCheck = async (checkedKeys: any, event: any) => {
    setCheckedKeys(checkedKeys)

    const categoriesQuery = getQueryParams(queryKeys.CATEGORIES)
    let newCategoriesQuery = ''

    if (event.checked) {
      const selectedCategories = new Set(
        categoriesQuery ? [...categoriesQuery.split(','), ...checkedKeys] : checkedKeys,
      )

      newCategoriesQuery = [...selectedCategories].join(',')
    } else {
      const uncheckedNodeWithChildren: string[] = [
        event.node.key,
        ...event.node.children.map((c: { title: string; key: string }) => c.key),
      ]

      newCategoriesQuery = categoriesQuery
        .split(',')
        .filter((key: string) => !uncheckedNodeWithChildren.includes(key))
        .join(',')
    }

    await push({
      search: serialize(queryKeys.CATEGORIES, newCategoriesQuery),
    })

    await debounceCheck()
  }

  const debounceCheck = useMemo(
    () =>
      _.debounce(() => {
        queryClient
          .fetchQuery<IProductWithPaginationResponse>(CATALOG_KEY)
          .then(() => {
            message.success('Фильтрация прошла успешно!')
          })
          .catch(() => {
            message.error('Произошла ошибка! Попробуйте повторить позже')
          })
      }, 800),
    [],
  )

  return (
    <Tree
      checkedKeys={checkedKeys}
      checkable
      selectable={false}
      onCheck={onCheck}
      treeData={childCategories}
    />
  )
}

export default CatalogTree
