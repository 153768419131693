import React from 'react'
import { Typography } from 'antd'

interface props {}

const NoDataText: React.FC<props> = () => {
  return <Typography.Text type={'secondary'}>Данные отсутствуют</Typography.Text>
}

export default NoDataText
