import React from 'react'
import styles from './filter-button.module.scss'
import icons from "@assets/images/icons.svg"
import {MutedButton} from "@app/shared"

interface props {
	onClick: () => void
	text: string
}

const FilterButton: React.FC<props> = ({onClick, text}) => {
	return (
		<div className={styles.buttonWrapper}>
			<MutedButton ghostOnMobile={true} onClick={onClick}>
				<svg className={styles.icon} width={20} fill={'#3F4842'} height={20}>
					<use href={icons + '#params'} />
				</svg>
				<span className={styles.text}>{text}</span>
			</MutedButton>
		</div>
	)
}

export default FilterButton
