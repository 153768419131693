import React from 'react'
import {
  ActiveCartCounter,
  Container,
  ErrorBoundary,
  PageInfo,
  Pagination,
  ProductListTable,
  RemoveFromFavorite,
} from '@app/shared'
import { FAVORITES_KEY, useFavoriteProducts } from '@app/hooks/query/favorites'
import { PageHeader, Skeleton } from 'antd'
import { ICartCounterProps } from '@app/interfaces/cart'

interface props {}

const FavoritesPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useFavoriteProducts()

  if (isLoading) return <Skeleton active />

  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const tableTitle = () => <React.Fragment>Список товаров</React.Fragment>
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={FAVORITES_KEY} />
  const counterFunction = (props: ICartCounterProps) => <ActiveCartCounter {...props} />
  const removeProductFunction = (productId: number) => <RemoveFromFavorite productId={productId} />

  return (
    <React.Fragment>
      <PageInfo title={'Избранное'} />
      <Container>
        <PageHeader title={'Избранное'} />
        <ProductListTable
          asyncSorting={false}
          invalidateQueryKey={FAVORITES_KEY}
          counterFunction={counterFunction}
          removeProductFunction={removeProductFunction}
          data={data}
          tableTitle={tableTitle}
          tableFooter={tableFooter}
        />
      </Container>
    </React.Fragment>
  )
}

export default FavoritesPage
