import React from 'react'
import { Form, Input } from 'antd'
import styles from './comment-input.module.scss'

interface props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
}

const CommentInput: React.FC<props> = ({
  name,
  label = '',
  placeholder = 'Введите текст сообщения',
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: 'Поле обязательно для заполнения',
        },
      ]}
    >
      <Input.TextArea className={styles.input} rows={window.screen.width < 768 ? 1 : 4} size={'large'} placeholder={placeholder} />
    </Form.Item>
  )
}

export default CommentInput
