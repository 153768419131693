import React from 'react'
import {CloseOutlined} from "@ant-design/icons"

interface props {

}

const CanceledIcon: React.FC<props> = () => {
	const style = {color: '#ff5119'}

	return (
		<CloseOutlined style={style} />
	)
}

export default CanceledIcon
