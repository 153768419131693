import React from 'react'
import { Dropdown, Menu } from 'antd'
import styles from './layout-header-user.module.scss'
import icons from '@assets/images/icons.svg'
import { Link } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import { useLogout } from '@app/hooks/useLogout'

interface props {}

const LayoutHeaderUser: React.FC<props> = () => {
  const { data } = useAuthUserInformation()
  const { logout, isLoading } = useLogout()

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={DashboardRoutes.PROFILE}>Профиль</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={logout} disabled={isLoading} danger={true}>
        Выйти
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement={'bottomRight'} trigger={['click']}>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <svg className={styles.icon}>
            <use href={icons + '#user-cabinet'} />
          </svg>
        </div>
        <span className={styles.text}>{data?.name}</span>
        <svg className={styles.chevron}>
          <use href={icons + '#chevron-down'} />
        </svg>
      </div>
    </Dropdown>
  )
}

export default LayoutHeaderUser
