import React from 'react'

interface props {
	weight: number
}

const CartWeight: React.FC<props> = ({weight}) => {
	return (
		<React.Fragment>
			<strong>Вес: </strong> <span>{weight} кг</span>
		</React.Fragment>
	)
}

export default CartWeight
