import React, { useContext, useEffect, useState } from 'react'
import styles from './checkout-page.module.scss'
import { CartInfo, Container, ErrorBoundary, InformationCard, PageInfo } from '@app/shared'
import { Button, Form, Input, message, PageHeader, Select, Skeleton } from 'antd'
import { CheckoutTable } from './components'
import { CART_KEY, useActiveCart } from '@app/hooks/query/active-cart'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { createNewOrderApi } from '@api/rest/orders'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { AxiosResponse } from 'axios'
import { INewOrder, IOrder } from '@app/interfaces/orders'
import { queryClient } from '@app/index'
import { UserOrganizationContext } from '@app/contexts/userOrganizationContext'

interface props {}

interface LocationState {
  organizationId: string | undefined
}

const CheckoutPage: React.FC<props> = () => {
  const { organization } = useContext(UserOrganizationContext)
  const [address, setAddress] = useState<string>()
  const { push } = useHistory()
  const { state } = useLocation<LocationState>()
  const { data, isLoading, isError } = useActiveCart()

  useEffect(() => {
    if (!state?.organizationId) {
      push(DashboardRoutes.CART)
    }
  }, [])

  const { mutateAsync, isLoading: isNewOrderLoading } = useMutation(createNewOrderApi, {
    onSuccess: async (response: AxiosResponse<IOrder>) => {
      queryClient.refetchQueries(CART_KEY)
      message.success('Заказ успешно оформлен')
      push({
        pathname: DashboardRoutes.ORDER_COMPLETE,
        state: response.data.number,
      })
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { items, ...info } = data!

  const setAddressHandle = (addressId: string) => setAddress(addressId)

  const onFinish = (values: INewOrder) => mutateAsync(values)

  const tableFooter = () => (
    <CartInfo cartInfo={info}>
      <Button
        loading={isNewOrderLoading}
        disabled={isNewOrderLoading || !address}
        form={'checkout-form'}
        block
        type={'primary'}
        htmlType={'submit'}
      >
        Оформить заказ
      </Button>
    </CartInfo>
  )

  return (
    <React.Fragment>
      <PageInfo title={'Оформление заказа'} />
      <Container>
        <PageHeader title={'Оформление заказа'} />
        <Form
          className={[styles.form, 'ant-input-dark-bg'].join(' ')}
          id={'checkout-form'}
          onFinish={onFinish}
          initialValues={{
            cart_id: data!.id,
            organization_id: state.organizationId,
          }}
        >
          <InformationCard title={'Доставка'}>
            <div className={styles.inputs}>
              <Form.Item name={'cart_id'} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name={'organization_id'} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'address_id'}
                rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
              >
                <Select
                  size={'large'}
                  placeholder={'Выберите адрес доставки'}
                  onChange={setAddressHandle}
                >
                  {organization?.addresses.map(({ id, title }) => (
                    <Select.Option value={id} key={id}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'comment'}>
                <Input.TextArea placeholder={'Комментарий к заказу'} size={'large'} rows={1} />
              </Form.Item>
            </div>
          </InformationCard>
          <br />
          <CheckoutTable data={items} tableFooter={tableFooter} />
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CheckoutPage
