import React from 'react'
import styles from './order-status.module.scss'
import { Divider } from 'antd'
import { CanceledIcon, CheckIcon, InProgressIcon } from '@app/shared'
import { IOrderStatus } from '@app/interfaces/orders'

interface props {
  statusTitle: string
  orderUpdateDate: string
  statusCode: IOrderStatus['code']
}

const OrderStatus: React.FC<props> = ({ statusCode, statusTitle, orderUpdateDate }) => {
  const renderStatus = () => {
    switch (statusCode) {
      case 'cancelled':
        return <CanceledIcon />
      case 'agreed':
        return <InProgressIcon />
      default:
        return <CheckIcon />
    }
  }

  return (
    <div className={styles.status}>
      {/*{renderStatus()}*/}
      <div className={styles.text}>
        {statusTitle} <Divider type={'vertical'} />{' '}
        <span className={styles.date}>{new Date(orderUpdateDate).toLocaleDateString()}</span>
      </div>
    </div>
  )
}

export default React.memo(OrderStatus)
