import React from 'react'
import styles from './table-mobile-title.module.scss'

interface props {
  title: string
}

// Компонент - подсказка в таблице на мобильной версии
const TableMobileTitle: React.FC<props> = ({ title }) => {
  return <span className={styles.header}>{title}:</span>
}

export default React.memo(TableMobileTitle)
