import React, { useEffect } from 'react'
import styles from './order-complete.module.scss'
import { Container, LinkButton, PageInfo } from '@app/shared'
import { Card, PageHeader } from 'antd'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { useHistory, useLocation } from 'react-router-dom'

interface props {}

const OrderCompletePage: React.FC<props> = () => {
  const { state } = useLocation<number>()
  const { push } = useHistory()

  useEffect(() => {
    if (!state) {
      return push({
        pathname: DashboardRoutes.CART,
      })
    }
  }, [state])

  return (
    <React.Fragment>
      <PageInfo title={'Заказ оформлен'} />
      <Container>
        <PageHeader title={'Заказ оформлен'} />
        <Card>
          <div className={styles.text}>
            <p>Ваш заказ успешно оформлен!</p>
            <p>Мы свяжемся с вами в ближайшее время.</p>
            <p>
              Номер вашего заказа: <strong>{state}</strong>
            </p>
          </div>
          <div className={styles.buttons}>
            <LinkButton url={DashboardRoutes.ORDERS} ghost={true}>
              Мои заказы
            </LinkButton>
            <LinkButton url={DashboardRoutes.CATALOG}>Перейти в подбор товаров</LinkButton>
          </div>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default OrderCompletePage
