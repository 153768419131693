import React from 'react'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryClient } from '@app/index'
import { ICart } from '@app/interfaces/cart'
import { message } from 'antd'
import { RemoveButton } from '@app/shared'
import { removeProductFromActiveCartApi } from '@api/rest/active-cart'
import { deleteItemFromSavedCartApi } from '@api/rest/carts'
import { CART_KEY } from '@app/hooks/query/active-cart'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'

interface props {
  productId: number
  cartId?: number
}

const RemoveFromCart: React.FC<props> = ({ productId, cartId }) => {
  const { mutateAsync: removeFromActiveCart, isLoading: activeCartLoading } = useMutation(
    removeProductFromActiveCartApi,
    {
      onSuccess: (response: AxiosResponse<any>) => {
        queryClient.invalidateQueries(CART_KEY)
      },
      onError: () => {
        message.error('Ошибка! Что-то пошло не так')
      },
    },
  )

  const { mutateAsync: removeFromSavedCart, isLoading: savedCartsLoading } = useMutation(
    deleteItemFromSavedCartApi,
    {
      onSuccess: ({ data }: AxiosResponse<ICart>) => {
        const oldData = queryClient.getQueryData<ICart[]>(ALL_CARTS_KEY)

        queryClient.setQueryData(
          ALL_CARTS_KEY,
          oldData?.map((cart) => (cart.id === data.id ? data : cart)),
        )
      },
      onError: () => {
        message.error('Ошибка! Что-то пошло не так')
      },
    },
  )

  const submitFunction = () =>
    cartId
      ? removeFromSavedCart({ product_id: productId, cart_id: cartId })
      : removeFromActiveCart(productId)

  return (
    <RemoveButton
      isLoading={activeCartLoading || savedCartsLoading}
      submitFunction={submitFunction}
    />
  )
}

export default React.memo(RemoveFromCart)
