import React from 'react'
import { Descriptions, Form, Input, message } from 'antd'
import { IUserInformation } from '@app/interfaces/user'
import { useMutation } from 'react-query'
import { changeUserAdditionalPhoneApi } from '@api/rest/auth'
import { EnterOutlined, LoadingOutlined } from '@ant-design/icons'
import { PatternFormat } from 'react-number-format'

interface props {
  data: Omit<IUserInformation, 'holding'>
}

const ProfilePersonalInformation: React.FC<props> = ({ data }) => {
  const [form] = Form.useForm()
  const { isLoading, mutate } = useMutation(changeUserAdditionalPhoneApi, {
    onSuccess: () => {
      message.success('Дополнительный номер успешно изменен')
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте позже')
    },
  })

  const handleChangeAdditionalPhone = (data: { additional_phone: string }) =>
    mutate(data.additional_phone)

  return (
    <Descriptions
      bordered={true}
      column={1}
      layout={window.screen.width > 576 ? 'horizontal' : 'vertical'}
    >
      <Descriptions.Item label="Ф.И.О">{data.name}</Descriptions.Item>
      <Descriptions.Item label="Должность">{data.position}</Descriptions.Item>
      <Descriptions.Item label="Номер телефона">{data.phone}</Descriptions.Item>
      <Descriptions.Item label="Дополнительный номер телефона">
        <Form
          form={form}
          onFinish={handleChangeAdditionalPhone}
          initialValues={{ additional_phone: data.additional_phone }}
        >
          <Form.Item noStyle name={'additional_phone'}>
            <PatternFormat
              format="+# (###) ### ## ##"
              customInput={Input}
              bordered={false}
              disabled={isLoading}
              placeholder={'Указать дополнительный номер телефона'}
              suffix={isLoading ? <LoadingOutlined /> : <EnterOutlined onClick={form.submit} />}
            />
          </Form.Item>
        </Form>
      </Descriptions.Item>
      <Descriptions.Item label="Эл. Почта">{data.email}</Descriptions.Item>
    </Descriptions>
  )
}

export default ProfilePersonalInformation
