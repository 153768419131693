import React from 'react'
import { Container, InformationCard, PageInfo } from '@app/shared'
import { PageHeader } from 'antd'
import { SupportTabs } from '@app/pages/support-page/components'

interface props {}

const SupportPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <PageInfo title={'Обратная связь'} />
      <Container>
        <PageHeader title={'Обратная связь'} />
        <InformationCard>
          <SupportTabs />
        </InformationCard>
      </Container>
    </React.Fragment>
  )
}

export default SupportPage
