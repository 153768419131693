import { useQuery } from 'react-query'
import { getAllDocumentsApi, getDocumentsFiltersApi } from '@api/rest/documents'
import { IDocumentFiltersResponse, IDocumentsResponse } from '@app/interfaces/documents'
import { AxiosResponse } from 'axios'

export const DOCUMENTS_KEY = 'documents_key'
export const DOCUMENTS_FILTERS_KEY = 'documents_filters'

export function useDocuments(id: number) {
  return useQuery<IDocumentsResponse, Error>(DOCUMENTS_KEY, () =>
    getAllDocumentsApi(id).then((response: AxiosResponse<IDocumentsResponse>) => response.data),
  )
}

export function useDocumentsFilters() {
  return useQuery<IDocumentFiltersResponse, Error>(
    DOCUMENTS_FILTERS_KEY,
    () =>
      getDocumentsFiltersApi().then(
        (response: AxiosResponse<IDocumentFiltersResponse>) => response.data,
      ),
    {
      refetchOnMount: false,
    },
  )
}
