import React from 'react'
import styles from './message-form.module.scss'
import { Button, Form, Input, message } from 'antd'
import { CommentInput } from '@app/shared'
import { IMessageData } from '@app/interfaces'
import { useMutation } from 'react-query'
import { sendSupportMessageApi } from '@api/rest/support'

interface props {
  subject: string
}

const MessageForm: React.FC<props> = ({ subject }) => {
  const [form] = Form.useForm()
  const { isLoading, mutateAsync } = useMutation(sendSupportMessageApi, {
    onSuccess: (response) => {
      message.success(response.data.message)
      form.resetFields()
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const onFinish = (values: IMessageData) => mutateAsync(values)

  return (
    <Form
      form={form}
      className={styles.form}
      initialValues={{ subject }}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Form.Item hidden={true} name={'subject'}>
        <Input />
      </Form.Item>
      <CommentInput name={'message'} required={true} />
      <Form.Item>
        <Button
          className={styles.button}
          type={'primary'}
          htmlType={'submit'}
          disabled={isLoading}
          loading={isLoading}
        >
          Отправить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default MessageForm
