import React, { useEffect } from 'react'
import { IUserOrganization } from '@app/interfaces/user'
import { ORGANIZATION_ID } from '@app/constants/cart'
import { useAuthUserInformation } from '@app/hooks/query/auth'

type UserOrganizationContextType = IUserOrganization | undefined

export interface IUserOrganizationContext {
  organization: UserOrganizationContextType
  setOrganization: (org: number) => void
}

export const UserOrganizationContext = React.createContext<IUserOrganizationContext>({
  organization: undefined,
  setOrganization: (id) => {
    console.log(id)
  },
})

export const UserOrganizationProvider: React.FC = ({ children }) => {
  const { isSuccess, data } = useAuthUserInformation()
  const [organization, setOrganization] = React.useState<UserOrganizationContextType>(undefined)
  const setNewOrganization = (organizationId: number) => {
    setOrganization(data?.holding.organizations.find(({ id }) => id === organizationId))
    localStorage.setItem(ORGANIZATION_ID, organizationId.toString())
  }

  useEffect(() => {
    if (data && isSuccess) {
      setOrganization(
        data.holding.organizations.find((org) =>
          org.id.toString() === localStorage.getItem(ORGANIZATION_ID) ? org : org.default,
        ),
      )
    }
  }, [isSuccess, data])

  return (
    <UserOrganizationContext.Provider value={{ organization, setOrganization: setNewOrganization }}>
      {children}
    </UserOrganizationContext.Provider>
  )
}
