import React, { useContext } from 'react'
import styles from './layout-header-organization.module.scss'
import { UserOrganizationContext } from '@app/contexts/userOrganizationContext'

interface props {}

const LayoutHeaderOrganization: React.FC<props> = () => {
  const { organization } = useContext(UserOrganizationContext)
  if (!organization) return <React.Fragment />

  return <div className={styles.org}>{organization.title}</div>
}

export default LayoutHeaderOrganization
