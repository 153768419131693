import React from 'react'
import styles from './product-name-with-image.module.scss'
import ProductImage from '@app/shared/product-image/product-image'
import { ProductBadge } from '@app/shared'
import { IPromoTag } from '@app/interfaces/promotag'
import { Space } from 'antd'

interface props {
  title: string
  imagePath: string
  sku: string
  brand?: string
  promotags?: IPromoTag[]
}

const ProductNameWithImage: React.FC<props> = ({ title, imagePath, sku, promotags }) => {
  return (
    <div className={styles.block}>
      <ProductImage imagePath={imagePath} />
      <div className={styles.content}>
        <p className={styles.text}>{title}</p>
        <p className={styles.text}>
          <span className={styles.char}>Артикул:</span> {sku}
        </p>
        <p className={styles.text}>
          <Space>
            {promotags?.map((promotag) => (
              <ProductBadge title={promotag.title} color={promotag.color} key={promotag.id} />
            ))}
          </Space>
        </p>
      </div>
    </div>
  )
}

export default React.memo(ProductNameWithImage)
