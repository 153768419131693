import React from 'react'
import { DocumentsTable } from '@app/pages/documents-page/components'
import { ErrorBoundary, Pagination } from '@app/shared'
import { DOCUMENTS_KEY, useDocuments } from '@app/hooks/query/documents'
import { Skeleton } from 'antd'

export interface DocumentAccountsProps {}

const DocumentAccounts: React.FC<DocumentAccountsProps> = () => {
  const { isLoading, isError, data: response } = useDocuments(1)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={DOCUMENTS_KEY} />

  return <DocumentsTable documents={data} tableFooter={tableFooter} />
}

export default DocumentAccounts
