import React from 'react'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import { useAllCarts } from '@app/hooks/query/carts'
import { SavedCart } from '@app/pages/saved-carts-page/components'

interface props {}

const SavedCartsPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useAllCarts()

  if (isLoading) return <Skeleton active />

  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Отложенные корзины'} />
      <Container>
        <PageHeader title={'Отложенные корзины'} />
        <Row gutter={[0, 30]}>
          {response!.map((cart) => (
            <Col xs={24} key={cart.id}>
              <SavedCart cart={cart} />
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default SavedCartsPage
