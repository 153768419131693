import { useQuery } from 'react-query'
import { getAllFavoritesApi } from '@api/rest/favorites'
import { AxiosResponse } from 'axios'
import { IProductWithPaginationResponse } from '@app/interfaces'

export const FAVORITES_KEY = 'favorites'

export function useFavoriteProducts() {
  return useQuery<IProductWithPaginationResponse, Error>(FAVORITES_KEY, () =>
    getAllFavoritesApi().then(
      (response: AxiosResponse<IProductWithPaginationResponse>) => response.data,
    ),
  )
}
