import React from 'react'
import { Helmet } from 'react-helmet'

interface props {
  title: string
}

const PageInfo: React.FC<props> = ({ title }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </React.Fragment>
  )
}

export default React.memo(PageInfo)
