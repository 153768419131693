import React from 'react'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
import styles from './information-card.module.scss'

interface props {
  title?: string
  url?: string
  detailsText?: string
}

const InformationCard: React.FC<props> = ({ title, url, detailsText = 'Подробнее', children }) => {
  return (
    <Card
      className={styles.card}
      title={title && <strong>{title}</strong>}
      extra={url ? <Link to={url}>{detailsText}</Link> : false}
    >
      {children}
    </Card>
  )
}

export default InformationCard
