import { Button, Dropdown, Menu, message } from 'antd'
import React, { useContext } from 'react'
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { DropdownIcon } from '@app/shared'
import { useMutation } from 'react-query'
import { changeCartStatus } from '@api/rest/carts'
import { queryClient } from '@app/index'
import { CART_KEY } from '@app/hooks/query/active-cart'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'
import { downloadActiveCartApi } from '@api/rest/active-cart'
import FileDownload from 'js-file-download'
import { CartDownload } from '../index'
import { UserOrganizationContext } from '@app/contexts/userOrganizationContext'

interface props {
  cartId: number
}

const CartDropdown: React.FC<props> = ({ cartId }) => {
  const { organization } = useContext(UserOrganizationContext)

  const { mutateAsync, isLoading } = useMutation(changeCartStatus, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CART_KEY)
      await queryClient.invalidateQueries(ALL_CARTS_KEY)
      message.success('Корзина успешно отложена')
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const handleSaveCart = () => mutateAsync({ id: cartId, active: false })
  const handleDownloadCart = () => {
    if (!organization) return message.error('Сначала выберите организацию')

    downloadActiveCartApi(organization.id)
      .then((response) => {
        FileDownload(
          response.data,
          organization.title + '-' + new Date().toLocaleDateString() + '.xlsx',
        )
        message.success('Корзина загружена успешно')
      })
      .catch((e) => {
        message.error(e.response.data.message)
      })
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<SaveOutlined />} disabled={isLoading} onClick={handleSaveCart}>
        В отложенные корзины
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={handleDownloadCart} icon={<DownloadOutlined />}>
        Скачать файл
      </Menu.Item>
      <Menu.Divider />
      <CartDownload key={'3'} />
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button size={'small'} shape={'circle'}>
        <DropdownIcon />
      </Button>
    </Dropdown>
  )
}

export default CartDropdown
