import React from 'react'
import makeRequest from "@api/makeRequest"

export const downloadPriceListApi = (
  checkedKeys: { checked: React.Key[]; halfChecked: React.Key[] } | React.Key[],
) => {
	return makeRequest({
		url: '/api/lk/price-list',
		method: 'POST',
		responseType: 'blob',
		data: {
			categories: checkedKeys
		}
	})
}
