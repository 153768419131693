import React from 'react'
import { RemoveButton } from '@app/shared'
import { useMutation } from 'react-query'
import { removeFavoriteProductApi } from '@api/rest/favorites'
import { message } from 'antd'
import { queryClient } from '@app/index'
import { IProductWithPaginationResponse } from '@app/interfaces'
import { FAVORITES_KEY } from '@app/hooks/query/favorites'

interface props {
  productId: number
}

const RemoveFromFavorite: React.FC<props> = ({ productId }) => {
  const { isLoading, mutateAsync } = useMutation(removeFavoriteProductApi, {
    onSuccess: () => {
      const oldData = queryClient.getQueryData<IProductWithPaginationResponse>(FAVORITES_KEY)

      queryClient.setQueryData(FAVORITES_KEY, {
        ...oldData,
        data: oldData?.data.filter((product) => product.id !== productId),
      })
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const submitFunction = () => mutateAsync(productId)

  return <RemoveButton isLoading={isLoading} submitFunction={submitFunction} />
}

export default React.memo(RemoveFromFavorite)
