import React, { useEffect, useState } from 'react'
import styles from './layout.module.scss'
import { Breadcrumbs, Container } from '@app/shared'
import { LayoutFooter, LayoutHeader, LayoutMenu } from './components'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import AccessDenied from '@app/layout/access-denied'

interface props {}

const Layout: React.FC<props> = ({ children }) => {
  const [menu, setMenu] = useState<boolean>(false)
  const menuHandler = () => setMenu(!menu)

  const { data } = useAuthUserInformation()

  useEffect(() => {
    menu ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '')
  }, [menu])

  if (data && !data.holding.active) return <AccessDenied />

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <LayoutMenu visible={menu} menuHandler={menuHandler} />
        <div className={styles.content}>
          <LayoutHeader visible={menu} menuHandler={menuHandler} />
          <main className={styles.main}>
            <Container>
              <Breadcrumbs />
            </Container>
            {children}
          </main>
          <LayoutFooter />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout
