import { ICatalogFiltersCategory } from '@app/interfaces/filters'

export const getCategoriesKey = (categories: ICatalogFiltersCategory[]) => {
  const keys: string[] = []

  categories.forEach((category) => {
    if (!category.children.length) {
      keys.push(category.key)
    }
    category.children.forEach((subCategory) => keys.push(subCategory.key))
  })

  return keys
}
