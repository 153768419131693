import React from 'react'

import styles from './banners.module.css'
import { useBanners } from '@app/hooks/query/banners'
import { Image, Skeleton } from 'antd'
import { ErrorBoundary } from '@app/shared'

export interface BannersProps {}

const Banners: React.FC<BannersProps> = () => {
  const { data, isLoading, isError } = useBanners()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />
  if (!data?.length) return <React.Fragment />

  const [banner] = data?.slice(0, 1)

  return (
    <a href={banner.link} target={'_blank'} rel={'noopener noreferrer'} className={styles.banners}>
      <Image className={styles.mobile} width={'100%'} src={banner.mobile} preview={false} />
      <Image className={styles.tablet} width={'100%'} src={banner.tablet} preview={false} />
      <Image className={styles.desktop} width={'100%'} src={banner.desktop} preview={false} />
    </a>
  )
}

export default Banners
