import { getBanners } from '@api/rest/banners'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { IBanner } from '@app/interfaces/banners'

export const BANNERS_KEY = 'banners-key'
export function useBanners(options?: UseQueryOptions<IBanner[], Error>) {
  return useQuery<IBanner[], Error>(
    BANNERS_KEY,
    () => getBanners().then((response: AxiosResponse<any>) => response.data),
    {
      ...options,
    },
  )
}
