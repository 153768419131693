import makeRequest from '@api/makeRequest'
import axios from 'axios'
import { history } from '@api/history'
import queryString from 'query-string'

export const getAllDocumentsApi = (id: number) => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()

  return makeRequest({
    url: '/api/lk/documents',
    cancelToken: source.token,
    params: { ...queryString.parse(history.location.search), type_id: id },
  })
}

export const getDocumentsFiltersApi = () => {
  return makeRequest({
    url: '/api/lk/documents/filters',
  })
}
