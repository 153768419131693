import React from 'react'
import { Container, ErrorBoundary, FiltersContainer, PageInfo } from '@app/shared'
import { OrdersFilters, OrdersTable, OrdersTags } from './components'
import { PageHeader, Skeleton } from 'antd'
import { useOrders } from '@app/hooks/query/order'

interface props {}

const OrdersPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useOrders()

  if (isLoading) return <Skeleton active />

  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Мои заказы'} />

      <Container>
        <PageHeader title={'Мои заказы'} />
        <FiltersContainer>
          <OrdersTags />
          <OrdersFilters />
        </FiltersContainer>
        <OrdersTable data={data} pagination={pagination} />
      </Container>
    </React.Fragment>
  )
}

export default OrdersPage
