import React from 'react'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { InformationCard } from '@app/shared'
import { useLatestOrders } from '@app/hooks/query/order'
import { Col, List, Row, Space, Typography } from 'antd'
import { FileDoneOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { priceFormatter } from '@app/utils/priceFormatter'
import { OrderStatus } from '@app/pages/orders-page/components'

interface props {
  ordersInProgress: number
}

const OrdersInfo: React.FC<props> = ({ ordersInProgress }) => {
  const { data, isLoading } = useLatestOrders()

  return (
    <InformationCard title={'Мои заказы'} detailsText={'Все заказы'} url={DashboardRoutes.ORDERS}>
      <List
        itemLayout="vertical"
        loading={isLoading}
        dataSource={data}
        renderItem={(order) => (
          <List.Item
            actions={[
              <div key={order.total}>Сумма заказа: {priceFormatter(order.total)} руб.</div>,
              <div key={order.created_at}>
                Дата заказа: {new Date(order.created_at).toLocaleDateString()}
              </div>,
            ]}
          >
            <List.Item.Meta
              title={
                <Row justify={'space-between'}>
                  <Col>
                    <Link to={'/orders/' + order.id}>
                      <strong>Заказ {order.number}</strong>
                    </Link>
                  </Col>
                  <Col>
                    <OrderStatus
                      statusTitle={order.status.title}
                      statusCode={order.status.code}
                      orderUpdateDate={order.updated_at}
                    />
                  </Col>
                </Row>
              }
              description={order.organization.title}
            />
          </List.Item>
        )}
      />
    </InformationCard>
  )
}

export default OrdersInfo
