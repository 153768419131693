import React from 'react'
import { IUserDeliveryAddress } from '@app/interfaces/user'
import {Descriptions, Typography} from 'antd'

interface props {
  addresses: IUserDeliveryAddress[]
}

const ProfileAddresses: React.FC<props> = ({ addresses }) => {
  return (
    <Descriptions
      title={'Адреса доставки'}
      bordered={true}
      column={1}
      layout={window.screen.width > 576 ? 'horizontal' : 'vertical'}
    >
      {addresses.length ? addresses.map((address, index) => (
        <Descriptions.Item label={'Адрес ' + (index + 1)} key={address.id}>
          {address.title}
        </Descriptions.Item>
      )) : <Typography.Text type={'secondary'}>Адреса доставки отсутствуют</Typography.Text>}
    </Descriptions>
  )
}

export default ProfileAddresses
