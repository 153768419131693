import { Drawer } from 'antd'
import React, { useState } from 'react'
import styles from './catalog-filters.module.scss'
import { FilterButton } from '@app/shared'
import { CatalogCategories, CatalogParameters } from '../index'
import { useCatalogFilters } from '@app/hooks/query/filters'

interface props {}

const CatalogFilters: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const showDrawer = () => setVisible(true)
  const closeDrawer = () => setVisible(false)
  const { data } = useCatalogFilters()

  return (
    <div className={styles.filters}>
      <FilterButton text={'Все фильтры'} onClick={showDrawer} />
      <Drawer
        title="Категории"
        destroyOnClose={false}
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={visible}
      >
        {data?.categories && <CatalogCategories categories={data.categories} />}
        {data?.tags && <CatalogParameters tags={data.tags} />}
      </Drawer>
    </div>
  )
}

export default React.memo(CatalogFilters)
