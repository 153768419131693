import React from 'react'
import { Table } from 'antd'
import { DownloadButton, NoDataText, TableMobileTitle } from '../../../../shared'
import { IDocument, IDocumentOrder, IDocumentType } from '@app/interfaces/documents'

interface props {
  tableFooter: () => JSX.Element
  documents: IDocument[]
}

const DocumentsTable: React.FC<props> = ({ tableFooter, documents }) => {
  const columns = [
    {
      title: 'Дата документа',
      dataIndex: 'created_at',
      render: (created_at: string) => (
        <React.Fragment>
          <TableMobileTitle title={'Дата документа'} />
          {new Date(created_at).toLocaleDateString()}
        </React.Fragment>
      ),
    },
    {
      title: 'Название',
      dataIndex: 'number',
      render: (number: string) => (
        <React.Fragment>
          <TableMobileTitle title={'Название'} />
          {number}
        </React.Fragment>
      ),
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      render: ({ title }: IDocumentType) => (
        <React.Fragment>
          <TableMobileTitle title={'Тип'} />
          {title}
        </React.Fragment>
      ),
    },
    {
      title: 'Заказ',
      dataIndex: 'document_order',
      render: (document_order: IDocumentOrder) => (
        <React.Fragment>
          <TableMobileTitle title={'Заказ'} />{' '}
          {document_order ? `№${document_order.order.id}` : <NoDataText />}
        </React.Fragment>
      ),
    },
    {
      title: 'Скачать',
      key: 'download',
      render: (record: IDocument) => (
        <React.Fragment>
          <TableMobileTitle title={'Скачать'} />
          <DownloadButton link={record.link} />
        </React.Fragment>
      ),
    },
  ]

  return (
    <Table
      rowKey={'id'}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      footer={tableFooter}
      dataSource={documents}
    />
  )
}

export default DocumentsTable
