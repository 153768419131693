import { useQuery } from 'react-query'
import { getAllFinancesApi } from '@api/rest/finances'
import { AxiosResponse } from 'axios'
import { IFinanceResponse } from '@app/interfaces/finances'

export const FINANCES_KEY = 'finances'

export function useFinances() {
  return useQuery<IFinanceResponse, Error>(FINANCES_KEY, () =>
    getAllFinancesApi().then((response: AxiosResponse<IFinanceResponse>) => response.data),
  )
}
