import React from 'react'
import styles from './layout-header.module.scss'
import { LayoutHeaderUser, LayoutHeaderCart } from '../index'
import { Link } from 'react-router-dom'
import logo from '@assets/images/logo-primary.svg'
import LayoutHeaderExit from '../layout-header-exit/layout-header-exit'
import LayoutHeaderOrganization from '@app/layout/components/layout-header-organization/layout-header-organization'
import env from '@sanchik97/react-dotenv-public-url'

interface props {
  menuHandler: () => void
  visible: boolean
}

const LayoutHeader: React.FC<props> = ({ menuHandler, visible }) => {
  return (
    <header className={styles.header}>
      <div className={styles.hamburgerWrapper} onClick={menuHandler}>
        <button
          className={[
            styles.hamburger,
            styles.hamburgerSlider,
            visible ? styles.isActive : '',
          ].join(' ')}
          type="button"
        >
          <span className={styles.hamburgerBox}>
            <span className={styles.hamburgerInner} />
          </span>
        </button>
      </div>

      <a href={env.SHOP_URL} target={'_blank'} rel={'noopener noreferrer'} className={styles.logo}>
        <img src={logo} width={130} alt="Bradex - Simple Life Solutions" />
      </a>
      <LayoutHeaderOrganization />

      <div className={styles.actions}>
        <LayoutHeaderCart />
        <LayoutHeaderUser />
        <LayoutHeaderExit />
      </div>
    </header>
  )
}

export default LayoutHeader
