import {useQuery} from "react-query"
import {ICart} from "@app/interfaces/cart"
import {getAllCartsApi} from "@api/rest/carts"
import {AxiosResponse} from "axios"

export const ALL_CARTS_KEY = 'all_carts'

export function useAllCarts() {
	return useQuery<ICart[], Error>(ALL_CARTS_KEY, () => getAllCartsApi().then((response:AxiosResponse<ICart[]>) => response.data))
}
