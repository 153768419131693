import React from 'react'
import styles from './select-organization.module.scss'
import { Divider, Select, Typography } from 'antd'
import { IUserOrganization } from '@app/interfaces/user'

const { Option } = Select

interface props {
  changeOrganization: (id: number) => void
  organizationId: number | undefined
  isLoading: boolean
  userOrganizations: IUserOrganization[]
}

const SelectOrganization: React.FC<props> = ({
  changeOrganization,
  organizationId,
  isLoading,
  userOrganizations,
}) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.orderText}>Оформление заказа на:</span>
      <Select
        placeholder={'Пожалуйста, выберите компанию'}
        className={styles.dropdown}
        size={'large'}
        defaultValue={organizationId}
        onChange={changeOrganization}
        disabled={isLoading}
        loading={isLoading}
      >
        {userOrganizations.map(({ id, default: isDefault, title }) => (
          <Option value={id} key={id}>
            {title}
            {isDefault && (
              <React.Fragment>
                <Divider type={'vertical'} />
                <Typography.Text type={'secondary'}>По умолчанию</Typography.Text>
              </React.Fragment>
            )}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectOrganization
