import React, { useState } from 'react'
import { Button, DatePicker, Form, Input, message, Space } from 'antd'
import styles from './finances-filters-form.module.scss'
import { MutedButton } from '@app/shared'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import { queryClient } from '@app/index'
import { FINANCES_KEY } from '@app/hooks/query/finances'

const { RangePicker } = DatePicker

interface props {
  hideDrawer: () => void
}

const FinancesFiltersForm: React.FC<props> = ({ hideDrawer }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { push } = useHistory()

  const onFinish = async (values: any) => {
    setIsLoading(true)

    const query = {
      number: values['number'],
      start_date: values['date'] && moment(values['date'][0]).format('DD-MM-YYYY'),
      end_date: values['date'] && moment(values['date'][1]).format('DD-MM-YYYY'),
    }

    await push({
      search: queryString.stringify(query),
    })

    await queryClient
      .invalidateQueries(FINANCES_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно')
        hideDrawer()
      })
      .catch(() => {
        message.error('Ошибка! Что-то пошло не так')
      })

    setIsLoading(false)
  }

  return (
    <Form onFinish={onFinish} layout={'vertical'} className={styles.form}>
      <Form.Item label={'Дата счета'} name={'date'}>
        <RangePicker format={'DD-MM-YYYY'} className={styles.range} size={'large'} />
      </Form.Item>
      <Form.Item label={'Номер счета'} name={'number'}>
        <Input className={styles.input} size={'large'} placeholder={'Введите номер счета'} />
      </Form.Item>
      <Space>
        <Button type={'primary'} htmlType={'submit'} disabled={isLoading} loading={isLoading}>
          Отправить
        </Button>
        <MutedButton type={'reset'} disabled={isLoading}>
          Сбросить
        </MutedButton>
      </Space>
    </Form>
  )
}

export default FinancesFiltersForm
