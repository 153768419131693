import React from 'react'
import { Container, ErrorBoundary, FiltersContainer, PageInfo, Pagination } from '@app/shared'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import { FINANCES_KEY, useFinances } from '@app/hooks/query/finances'
import { FinancesFilters, FinancesTable, FinancesTags } from './components'
import { priceFormatter } from '@app/utils/priceFormatter'

interface props {}

const FinancesPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useFinances()

  if (isLoading) return <Skeleton />
  if (isError) return <ErrorBoundary />

  const {
    documents: { data, ...pagination },
    unpaid_total,
  } = response!

  const tableTitle = () => (
    <Row justify={'space-between'}>
      <Col>Документы</Col>
      <Col>Сумма неоплаченных счетов: {priceFormatter(unpaid_total)} руб.</Col>
    </Row>
  )
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={FINANCES_KEY} />

  return (
    <React.Fragment>
      <PageInfo title={'Финансы'} />
      <Container>
        <PageHeader title={'Финансы'} />
        <FiltersContainer>
          <FinancesTags />
          <FinancesFilters />
        </FiltersContainer>
        <FinancesTable documents={data} tableTitle={tableTitle} tableFooter={tableFooter} />
      </Container>
    </React.Fragment>
  )
}

export default FinancesPage
