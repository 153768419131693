import React from 'react'
import { Link } from 'react-router-dom'
import styles from './order-info.module.scss'
import { DashboardRoutes } from '@app/constants/dashboard-routes'

interface props {
  id: number
  orderNumber: string
  organization_name: string
}

const OrderInfo: React.FC<props> = ({ id, orderNumber, organization_name }) => {
  return (
    <div className={styles.orderInfo}>
      <p className={styles.order}>Заказ {orderNumber}</p>
      <span className={styles.company}>{organization_name}</span>
      <Link to={DashboardRoutes.ORDERS + '/' + id}>Подробнее о заказе</Link>
    </div>
  )
}

export default React.memo(OrderInfo)
