import queryString from 'query-string'
import { history } from '@api/history'
import { queryKeys } from '@app/constants/query-keys'

export const serialize = (query: string | string[][], value?: any) => {
  const qs = queryString.parse(history.location.search)

  // Удаляем пагинацию при поиске и фильтрации
  if (query === queryKeys.Q || query === queryKeys.CATEGORIES || query === queryKeys.TAGS)
    delete qs['page']

  if (typeof query === 'string') {
    qs[query] = value
  } else {
    query.forEach(([key, value]) => {
      qs[key] = value
    })
  }

  //@ts-ignore
  const newSearch = Object.keys(qs)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(qs[k] as string)}`)
    .join('&')

  return `?${new URLSearchParams(newSearch)}`
}
