import React from 'react'
import { ProductQuantity } from '@app/shared'

interface props {
  quantity: number
  stock?: number
  productId: number
  maxInStock: number
  changeProductQuantity: (newQuantityValue: number, productId: number) => void
}

const OrderAdjustmentCounter: React.FC<props> = ({
  quantity,
  stock,
  productId,
  changeProductQuantity,
  maxInStock,
}) => {
  const newQuantity = (newValue: number) => changeProductQuantity(newValue, productId)

  return (
    <ProductQuantity
      maxInStock={maxInStock}
      quantity={quantity}
      handler={newQuantity}
      inStock={stock}
    />
  )
}

export default React.memo(OrderAdjustmentCounter)
