import React, { useState } from 'react'
import styles from './documents-filters-form.module.scss'
import { Button, Card, DatePicker, Form, message, Space } from 'antd'
import { MutedButton } from '@app/shared'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import queryString from 'query-string'
import { queryClient } from '@app/index'
import { DOCUMENTS_KEY } from '@app/hooks/query/documents'

const { RangePicker } = DatePicker

interface props {
  buttonText: 'Применить' | 'Запросить акт'
}

const DocumentsFiltersForm: React.FC<props> = ({ buttonText }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { push } = useHistory()

  const handleFilter = async (query: object) => {
    setIsLoading(true)
    await push({
      search: queryString.stringify(query),
    })

    await queryClient
      .invalidateQueries(DOCUMENTS_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно')
      })
      .catch(() => {
        message.error('Ошибка! Что-то пошло не так')
      })

    setIsLoading(false)
  }

  const onFinish = (values: any) => {
    const query = {
      start_date: values['date'] && moment(values['date'][0]).format('DD-MM-YYYY'),
      end_date: values['date'] && moment(values['date'][1]).format('DD-MM-YYYY'),
    }
    handleFilter(query)
  }

  const onReset = () => {
    const query = { start_date: '', end_date: '' }
    form.resetFields()
    handleFilter(query)
  }

  return (
    <Card title={'Фильтр'} className={styles.block}>
      <Form form={form} className={styles.form} onFinish={onFinish} layout={'vertical'}>
        <Form.Item label={'Период'} name={'date'} rules={[{ required: true }]}>
          <RangePicker format={'DD-MM-YYYY'} className={styles.range} size={'large'} />
        </Form.Item>
        <Space className={styles.buttons}>
          <Button type={'primary'} htmlType={'submit'} disabled={isLoading} loading={isLoading}>
            {buttonText}
          </Button>
          <MutedButton type={'reset'} disabled={isLoading} onClick={onReset}>
            Сбросить
          </MutedButton>
        </Space>
      </Form>
    </Card>
  )
}

export default DocumentsFiltersForm
