import React from 'react'
import { TableMobileTitle } from '@app/shared'
import { priceFormatter } from '@app/utils/priceFormatter'
import styles from './price-with-title.module.scss'

interface props {
  sum: number
  title: string
}

const PriceWithTitle: React.FC<props> = ({ sum, title }) => {
  return (
    <span className={styles.wrapper}>
      <TableMobileTitle title={title} /> <strong>{priceFormatter(sum)} руб.</strong>
    </span>
  )
}

export default PriceWithTitle
