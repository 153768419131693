import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  AuthPage,
  CartPage,
  CatalogPage,
  CheckoutPage,
  DocumentsPage,
  FavoritesPage,
  FinancesPage,
  HomePage,
  OrderAdjustmentPage,
  OrderCompletePage,
  OrderPage,
  OrdersPage,
  PriceListsPage,
  ProfilePage,
  SavedCartsPage,
  SupportPage,
} from './pages'
import { DashboardRoutes } from './constants/dashboard-routes'
import Layout from '@app/layout/layout'
import PrivateRoute from '@app/hoc/private-route'
import { SupportHistoryPage } from '@app/pages'

function Routes() {
  return (
    <Switch>
      <Route path={Object.values(DashboardRoutes)}>
        <Layout>
          <Switch>
            <PrivateRoute>
              <Route path={DashboardRoutes.HOME} exact component={HomePage} />
              <Route path={DashboardRoutes.PROFILE} component={ProfilePage} />

              <Route path={DashboardRoutes.ORDERS} exact component={OrdersPage} />
              <Route path={DashboardRoutes.ORDER} exact component={OrderPage} />
              <Route path={DashboardRoutes.ORDER_ADJUSTMENT} component={OrderAdjustmentPage} />

              <Route path={DashboardRoutes.CATALOG} component={CatalogPage} />

              <Route path={DashboardRoutes.CART} exact={true} component={CartPage} />
              <Route path={DashboardRoutes.CHECKOUT} component={CheckoutPage} />
              <Route path={DashboardRoutes.ORDER_COMPLETE} component={OrderCompletePage} />

              <Route path={DashboardRoutes.SAVED_CARTS} component={SavedCartsPage} />
              <Route path={DashboardRoutes.FAVORITES} component={FavoritesPage} />
              <Route path={DashboardRoutes.FINANCES} component={FinancesPage} />
              <Route path={DashboardRoutes.PRICE_LISTS} component={PriceListsPage} />
              <Route path={DashboardRoutes.DOCUMENTS} component={DocumentsPage} />
              <Route path={DashboardRoutes.SUPPORT} exact={true} component={SupportPage} />
              <Route path={DashboardRoutes.SUPPORT_HISTORY} component={SupportHistoryPage} />
            </PrivateRoute>
          </Switch>
        </Layout>
      </Route>

      <Route path={'/auth'} component={AuthPage} />

      <Redirect to={DashboardRoutes.HOME} />
    </Switch>
  )
}

export default Routes
