import React, { useState } from 'react'
import styles from './price-lists-section.module.scss'
import { Button, Collapse, message, Tree } from 'antd'
import { ICatalogFiltersCategory } from '@app/interfaces/filters'
import { useMutation } from 'react-query'
import { downloadPriceListApi } from '@api/rest/price-lists'
import FileDownload from 'js-file-download'

interface props {
  categories: ICatalogFiltersCategory[]
}

const { Panel } = Collapse

const PriceListsSection: React.FC<props> = ({ categories }) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])

  const onCheck = (
    checked: { checked: React.Key[]; halfChecked: React.Key[] } | React.Key[],
    event: any,
  ) => {
    if (Array.isArray(selectedKeys)) {
      if (event.checked) {
        const newSelectedKeys = new Set([...selectedKeys, ...Object.values(checked)])

        setSelectedKeys([...newSelectedKeys])
      } else {
        const uncheckedNodeWithChildren: string[] = [
          event.node.key,
          ...event.node.children.map((c: { title: string; key: string }) => c.key),
        ]

        setSelectedKeys(
          selectedKeys.filter((key) => !uncheckedNodeWithChildren.includes(key as string)),
        )
      }
    }
  }

  const { isLoading, mutateAsync } = useMutation(downloadPriceListApi, {
    onSuccess: async (response) => {
      await FileDownload(
        response.data,
        'Прайс-лист от ' + new Date().toLocaleDateString() + '.xlsx',
      )

      message.success('Прайс-лист успешно загружен')
      window.location.reload()
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const handleDownloadPrice = () => mutateAsync(selectedKeys)

  return (
    <div className={styles.sectionsWrapper}>
      <Collapse expandIconPosition={'right'}>
        {categories.map((category) => (
          <Panel key={category.id} header={category.title}>
            <Tree checkable selectable={false} onCheck={onCheck} treeData={category.children} />
          </Panel>
        ))}
      </Collapse>
      <div className={styles.button}>
        <Button
          size={'large'}
          type={'primary'}
          onClick={handleDownloadPrice}
          disabled={isLoading || !selectedKeys.length}
          loading={isLoading}
        >
          Скачать прайс-листы
        </Button>
      </div>
    </div>
  )
}

export default PriceListsSection
