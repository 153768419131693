import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'
import { message, Space } from 'antd'
import { FilterTag } from '@app/shared'
import { queryClient } from '@app/index'
import { FINANCES_KEY } from '@app/hooks/query/finances'
import { IQueryParams } from '@app/interfaces'
import {pickQueryParams} from "@app/utils/pickQueryParams"

interface props {}

const FinancesTags: React.FC<props> = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [queries, setQueries] = useState<IQueryParams>({})
  const { search } = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    const pickedQueryParams: IQueryParams = pickQueryParams('number', 'start_date', 'end_date')

    setQueries(pickedQueryParams)
  }, [search])

  const deleteFilter = async (key: string | number) => {
    setIsDisabled(true)
    const newQueries = { ...queries, [key]: '' }

    await push({
      search: queryString.stringify(newQueries),
    })

    await queryClient
      .fetchQuery(FINANCES_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно!')
      })
      .catch(() => {
        message.error('Прозиошла ошибка! Что-то пошло не так')
      })

    setIsDisabled(false)
  }

  return (
    <Space>
      {Object.entries(queries)
        .filter(([_, value]) => value)
        .map(([key, value]) => {
          return (
            <FilterTag
              id={key}
              key={key}
              title={value}
              handleFunction={deleteFilter}
              isDisabled={isDisabled}
            />
          )
        })}
    </Space>
  )
}

export default FinancesTags
