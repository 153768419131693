import React from 'react'
import { Button, Card, Result } from 'antd'
import styles from './layout.module.scss'
import { LogoutOutlined } from '@ant-design/icons'
import { AUTHENTICATED } from '@app/constants/auth'
import { queryClient } from '@app/index'
import { AUTH_KEY } from '@app/hooks/query/auth'

export interface AccessDeniedProps {}

const AccessDenied: React.FC<AccessDeniedProps> = () => {
  const onClick = () => {
    localStorage.removeItem(AUTHENTICATED)
    queryClient.removeQueries(AUTH_KEY)
    window.location.href = '/auth'
  }

  return (
    <div className={styles.denied}>
      <Card>
        <Result
          status={'error'}
          title={'Доступ заблокирован'}
          subTitle={
            'Доступ к Личному Кабинету был заблокирован! Если у вас есть вопросы, обратитесь к вашему менеджеру.'
          }
          extra={
            <Button size={'small'} type={'primary'} icon={<LogoutOutlined />} onClick={onClick}>
              Выйти
            </Button>
          }
        />
      </Card>
    </div>
  )
}

export default AccessDenied
