import React from 'react'
import {Button} from "antd"
import styles from "./remove-button.module.scss"
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons"

interface props {
	isLoading?: boolean,
	submitFunction: () => void
}

const RemoveButton: React.FC<props> = ({isLoading, submitFunction}) => {
	return (
		<Button className={styles.button}  disabled={isLoading} onClick={submitFunction}>
			{isLoading ? <LoadingOutlined/> : <CloseOutlined/>}
			<span className={styles.text}>Удалить товар</span>
		</Button>
	)
}

export default RemoveButton
