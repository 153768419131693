import React from 'react'
import { Information } from './components'
import { Container, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import Banners from '@app/shared/banners/banners'
import styles from './home-page.module.css'

interface props {}

const HomePage: React.FC<props> = () => {
  const { data, isLoading, isError } = useAuthUserInformation()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Главная'} />

      <Container className={styles.container}>
        <PageHeader title={'Главная'} />
        <Information data={data!} />
        <Banners />
      </Container>
    </React.Fragment>
  )
}

export default HomePage
