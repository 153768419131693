import axios from 'axios'
import makeRequest from '@api/makeRequest'
import { IAddProductToCart } from '@app/interfaces/cart'
import { ORGANIZATION_ID } from '@app/constants/cart'

export const getActiveCartApi = () => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()

  return makeRequest({
    url: '/api/lk/active-cart',
    cancelToken: source.token,
    params: {
      organization_id: localStorage.getItem(ORGANIZATION_ID),
    },
  })
}

export const changeItemQuantityInActiveCartApi = (data: IAddProductToCart) => {
  return makeRequest({
    url: '/api/lk/active-cart',
    method: 'POST',
    data,
  })
}

export const setActiveCartApi = (id: number) => {
  return makeRequest({
    url: '/api/lk/active-cart/' + id,
    method: 'PUT',
  })
}

export const removeProductFromActiveCartApi = (id: number) => {
  return makeRequest({
    url: '/api/lk/active-cart/' + id,
    method: 'DELETE',
  })
}

export const downloadActiveCartApi = (org_id: number) => {
  return makeRequest({
    url: `api/lk/active-cart/${org_id}/export`,
    responseType: 'blob',
  })
}

export const uploadActiveCartApi = (file: any) => {
  return makeRequest({
    url: '/api/lk/active-cart/import',
    method: 'POST',
    data: file,
  })
}
