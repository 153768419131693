import { INewOrder } from '@app/interfaces/orders'
import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { IAdjustmentProduct, IProduct } from '@app/interfaces'

export const getAllOrdersApi = () => {
  return makeRequest({
    url: '/api/lk/orders',
    params: queryString.parse(history.location.search),
  })
}

export const getLatestOrdersApi = () => {
  return makeRequest({
    url: '/api/lk/orders/latest',
  })
}

export const getOrderByIdApi = (id: string) => {
  return makeRequest({
    url: '/api/lk/orders/' + id,
  })
}

export const createNewOrderApi = (data: INewOrder) => {
  return makeRequest({
    url: '/api/lk/orders',
    method: 'POST',
    data,
  })
}
export const cancelOrderApi = (id: string) => {
  return makeRequest({
    url: '/api/lk/orders/' + id + '/cancel',
    method: 'PUT',
  })
}

export const repeatOrderApi = (id: number) => {
  return makeRequest({
    url: '/api/lk/orders/' + id + '/repeat',
    method: 'POST',
  })
}

export const postponeProductApi = (data: { orderId: number; productId: number }) => {
  return makeRequest({
    url: '/api/lk/orders/' + data.orderId + '/postpone/' + data.productId,
    method: 'POST',
  })
}

export const adjustmentOrderApi = (order: { id: number; order_items: IAdjustmentProduct[] }) => {
  return makeRequest({
    url: '/api/lk/orders/' + order.id,
    method: 'PUT',
    data: {
      order_items: order.order_items,
    },
  })
}
