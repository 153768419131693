import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './breadcrumbs.module.scss'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { useOrder } from '@app/hooks/query/order'
import { getOrderTitle } from '@app/utils/getOrderTitle'

interface props {}

const useOrderTitle = ({ match: { params } }: any) => {
  const { data, isLoading } = useOrder(params.id)

  if (isLoading) return <React.Fragment />

  const title = getOrderTitle(data?.number, data?.created_at)

  return <React.Fragment>{title}</React.Fragment>
}

const routes = [
  { breadcrumb: 'Главная', path: '/' },
  { breadcrumb: 'Оформление заказа', path: DashboardRoutes.CHECKOUT },
  { breadcrumb: 'Персональные данные', path: DashboardRoutes.PROFILE },
  { breadcrumb: 'Мои заказы', path: DashboardRoutes.ORDERS },
  { breadcrumb: useOrderTitle, path: DashboardRoutes.ORDER },
  { breadcrumb: 'Корректировка заказа', path: DashboardRoutes.ORDER_ADJUSTMENT },
  { breadcrumb: 'Каталог товаров', path: DashboardRoutes.CATALOG },
  { breadcrumb: 'Корзина', path: DashboardRoutes.CART },
  { breadcrumb: 'Отложенные корзины', path: DashboardRoutes.SAVED_CARTS },
  { breadcrumb: 'Избранное', path: DashboardRoutes.FAVORITES },
  { breadcrumb: 'Финансы', path: DashboardRoutes.FINANCES },
  { breadcrumb: 'Прайс-листы', path: DashboardRoutes.PRICE_LISTS },
  { breadcrumb: 'Документы', path: DashboardRoutes.DOCUMENTS },
  { breadcrumb: 'Обратная связь', path: DashboardRoutes.SUPPORT },
  { breadcrumb: 'История запросов', path: DashboardRoutes.SUPPORT_HISTORY },
  { breadcrumb: 'Заказ оформлен', path: DashboardRoutes.ORDER_COMPLETE },
]

const Breadcrumbs: React.FC<props> = () => {
  const bc = useBreadcrumbs(routes, {
    disableDefaults: true,
  })

  return (
    <ul className={styles.breadcrumbs}>
      {bc.map(({ match, breadcrumb }) => (
        <li className={styles.item} key={match.url}>
          <NavLink to={match.url}>{breadcrumb}</NavLink>
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumbs
