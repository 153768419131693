import { getSupportHistoryApi } from '@app/api/rest/support'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { ISupportHistoryResponse } from '@app/interfaces/support'

export const SUPPORT_KEY = 'support-key'

export function useSupportHistory(options?: UseQueryOptions<ISupportHistoryResponse, Error>) {
  return useQuery<ISupportHistoryResponse, Error>(
    [SUPPORT_KEY],
    () =>
      getSupportHistoryApi().then(
        (response: AxiosResponse<ISupportHistoryResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
