import React, { useEffect } from 'react'
import styles from './auth-page.module.scss'
import { Col, Row } from 'antd'
import { AuthForm } from './components'
import { AUTHENTICATED } from '@app/constants/auth'
import { useHistory } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { PageInfo } from '@app/shared'
import logo from '@assets/images/logo-primary.svg'

interface props {}

const AuthPage: React.FC<props> = () => {
  const { push } = useHistory()

  useEffect(() => {
    if (localStorage.getItem(AUTHENTICATED)) {
      return push(DashboardRoutes.HOME)
    }
  }, [push])

  return (
    <div className={styles.wrapper}>
      <PageInfo title={'Авторизация'} />
      <Row justify={'center'}>
        <Col xs={24}>
          <img className={styles.image} src={logo} width={175} />
        </Col>
        <Col xs={24} md={10} lg={8} xl={6} xxl={4}>
          <AuthForm />
        </Col>
      </Row>
    </div>
  )
}

export default AuthPage
