import makeRequest from '@api/makeRequest'
import { IMessageData } from '@app/interfaces'

export const sendSupportMessageApi = (data: IMessageData) => {
  return makeRequest({
    url: '/api/lk/support',
    method: 'POST',
    data,
  })
}

export const getSupportHistoryApi = () => {
  return makeRequest({
    url: '/api/lk/support/history',
  })
}
