import makeRequest from '@api/makeRequest'
import axios from 'axios'
import queryString from 'query-string'
import { history } from '@api/history'

export const getAllFinancesApi = () => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()

  return makeRequest({
    url: '/api/lk/finances',
    cancelToken: source.token,
    params: queryString.parse(history.location.search),
  })
}
