import React, { useState } from 'react'
import styles from './search-input.module.scss'
import { Form, Input, message } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { serialize } from '@app/utils/serializeQuery'
import { queryClient } from '@app/index'
import { getQueryParams } from '@app/utils/getQueryParams'
import { queryKeys } from '@app/constants/query-keys'

interface props {
  placeholder: string
  fetchingQueryKey: string
}

const SearchInput: React.FC<props> = ({ placeholder, fetchingQueryKey }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const { push } = useHistory()
  const [form] = Form.useForm()

  const handleSearch = async (values: { q: string }) => {
    setIsDisabled(true)

    await push({
      search: serialize(queryKeys.Q, values.q || ''),
    })

    await queryClient
      .fetchQuery(fetchingQueryKey)
      .then((response: any) => {
        if (!response.data.length) {
          message.error('Поиск не дал результатов')
        } else {
          message.success('Поиск успешно завершен')
        }
      })
      .catch(() => {
        message.error('Произошла ошибка. Попробуйте снова')
      })

    setIsDisabled(false)
  }

  return (
    <div className={[styles.wrapper, isDisabled ? styles.disabled : ''].join(' ')}>
      <Form
        form={form}
        initialValues={{ q: getQueryParams(queryKeys.Q) }}
        className={styles.form}
        onFinish={handleSearch}
      >
        <Form.Item name={queryKeys.Q}>
          <Input
            className={[styles.input, 'mobile-border-none'].join(' ')}
            size={'large'}
            placeholder={placeholder}
          />
        </Form.Item>
        <button type={'submit'} className={styles.btn}>
          {isDisabled ? <LoadingOutlined /> : <SearchOutlined />}
        </button>
      </Form>
    </div>
  )
}

export default React.memo(SearchInput)
