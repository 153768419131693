import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import { changeItemQuantityInActiveCartApi } from '@api/rest/active-cart'
import { queryClient } from '@app/index'
import { CART_KEY } from '@app/hooks/query/active-cart'
import _ from 'lodash'
import { message } from 'antd'
import { ProductQuantity } from '@app/shared'
import { IProduct, IProductWithPaginationResponse } from '@app/interfaces'
import { CATALOG_KEY } from '@app/hooks/query/catalog'
import { AxiosResponse } from 'axios'
import { FAVORITES_KEY } from '@app/hooks/query/favorites'

export interface props {
  productId: number
  quantity: number
  inStock?: number
  maxInStock: number
}

function setNewData(oldData: IProduct[], newProduct: IProduct) {
  return oldData.map((product) => (product.id === newProduct.id ? newProduct : product))
}

const ActiveCartCounter: React.FC<props> = ({ productId, quantity, inStock, maxInStock }) => {
  const { mutateAsync, isLoading } = useMutation(changeItemQuantityInActiveCartApi, {
    onSuccess: async (response: AxiosResponse<IProduct>) => {
      await queryClient.invalidateQueries(CART_KEY)

      const catalogStore = queryClient.getQueryData<IProductWithPaginationResponse>(CATALOG_KEY)
      const favoriteStore = queryClient.getQueryData<IProductWithPaginationResponse>(FAVORITES_KEY)

      if (catalogStore) {
        queryClient.setQueryData(CATALOG_KEY, {
          ...catalogStore,
          data: setNewData(catalogStore.data, response.data),
        })
      }
      if (favoriteStore) {
        queryClient.setQueryData(FAVORITES_KEY, {
          ...favoriteStore,
          data: setNewData(favoriteStore.data, response.data),
        })
      }
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const addToCartHandler = (quantity: number) => {
    return mutateAsync({
      product_id: productId,
      quantity,
    })
  }

  const debounceQuery = useMemo(() => _.debounce(addToCartHandler, 800), [])

  return (
    <ProductQuantity
      maxInStock={maxInStock}
      quantity={quantity}
      inStock={inStock}
      handler={debounceQuery}
      isLoading={isLoading}
    />
  )
}

export default React.memo(ActiveCartCounter)
