import makeRequest from '@api/makeRequest'
import { IAddProductToCart } from '@app/interfaces/cart'

export const getAllCartsApi = () => {
  return makeRequest({
    url: '/api/lk/carts',
  })
}

export const changeItemQuantityInSavedCartApi = ({
  cart_id,
  product_id,
  quantity,
}: IAddProductToCart) => {
  return makeRequest({
    url: '/api/lk/carts/' + cart_id,
    method: 'POST',
    data: {
      product_id,
      quantity,
    },
  })
}

export const deleteSavedCartApi = (cartId: number) => {
  return makeRequest({
    url: '/api/lk/carts/' + cartId,
    method: 'DELETE',
  })
}


export const changeCartStatus = (data: { id: number; active: boolean }) => {
  return makeRequest({
    url: '/api/lk/carts/' + data.id,
    method: 'PUT',
    data: {
      active: data.active
    }
  })
}


export const deleteItemFromSavedCartApi = ({
  product_id,
  cart_id,
}: {
  cart_id: number
  product_id: number
}) => {
  return makeRequest({
    url: '/api/lk/carts/' + cart_id + '/' + product_id,
    method: 'DELETE',
  })
}
