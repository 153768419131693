import React from 'react'
import styles from './order-renew.module.scss'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import { MutedButton } from '@app/shared'
import { useMutation } from 'react-query'
import { Button, message, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { repeatOrderApi } from '@api/rest/orders'
import { queryClient } from '@app/index'
import { CART_KEY } from '@app/hooks/query/active-cart'

interface props {
  orderId: number
}

const OrderRenew: React.FC<props> = ({ orderId }) => {
  const { push } = useHistory()

  const redirect = () => push(DashboardRoutes.CART)

  const { isLoading, mutateAsync } = useMutation(repeatOrderApi, {
    onSuccess: () => {
      notification.success({
        message: 'Операция прошла успешно.',
        duration: 3,
        description: `Перейдите на страницу корзины для оформления нового заказа.`,
        btn: (
          <Button size={'small'} type={'link'} onClick={redirect}>
            Перейти в корзину
          </Button>
        ),
      })
      queryClient.invalidateQueries(CART_KEY)
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const handleRenewOrder = () => mutateAsync(orderId)

  return (
    <div className={styles.renew}>
      <MutedButton onClick={handleRenewOrder} disabled={isLoading}>
        {isLoading ? <LoadingOutlined /> : <SyncOutlined />} Повторить заказ
      </MutedButton>
    </div>
  )
}

export default React.memo(OrderRenew)
