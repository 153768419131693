import React from 'react'
import { priceFormatter } from '@app/utils/priceFormatter'

interface props {
  total: number
}

const CartTotal: React.FC<props> = ({ total }) => {
  return (
    <React.Fragment>
      <strong>Итого: </strong> <span>{priceFormatter(total)} руб.</span>
    </React.Fragment>
  )
}

export default CartTotal
