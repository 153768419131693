import React from 'react'
import { Table } from 'antd'
import { PriceWithTitle, ProductNameWithImage, TableMobileTitle } from '@app/shared'
import { IProduct } from '@app/interfaces'

interface props {
  data: IProduct[]
  tableFooter: () => JSX.Element
}

const CheckoutTable: React.FC<props> = ({ data, tableFooter }) => {
  const columns = [
    {
      title: 'Наименование',
      key: 'name',
      render: ({ brand, sku, image_path, tags, title }: IProduct) => (
        <ProductNameWithImage
          promotags={tags}
          brand={brand?.title}
          sku={sku}
          imagePath={image_path}
          title={title}
        />
      ),
    },
    {
      title: 'Цена за 1 шт.',
      dataIndex: 'price',
      render: (price: number) => <PriceWithTitle title={'Цена за 1 шт.'} sum={price} />,
    },
    {
      title: 'Количество в корзине',
      dataIndex: 'quantity',
      render: (quantity: number) => (
        <React.Fragment>
          <TableMobileTitle title={'В корзине'} />
          {quantity}
        </React.Fragment>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      render: (sum: number) => <PriceWithTitle title={'Сумма'} sum={sum} />,
    },
  ]

  return (
    <Table
      rowKey={'id'}
      columns={columns}
      dataSource={data}
      title={() => 'Товары в корзине'}
      footer={tableFooter}
      pagination={false}
    />
  )
}

export default CheckoutTable
