import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { message, Space, Spin } from 'antd'
import { FilterTag } from '@app/shared'
import { queryClient } from '@app/index'
import { ORDERS_KEY } from '@app/hooks/query/order'
import { useAuthUserInformation } from '@app/hooks/query/auth'
import { IQueryParams } from '@app/interfaces'
import { pickQueryParams } from '@app/utils/pickQueryParams'

interface props {}

const OrdersTags: React.FC<props> = () => {
  const { data, isLoading } = useAuthUserInformation()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [queries, setQueries] = useState<IQueryParams>({})
  const { push } = useHistory()
  const { search } = useLocation()

  useEffect(() => {
    const pickedQueryParams = pickQueryParams('organization_id', 'id', 'created_at')
    setQueries(pickedQueryParams)
  }, [search])

  if (isLoading) return <Spin />

  const deleteFilter = async (key: string | number) => {
    setIsDisabled(true)
    const newQueries = { ...queries, [key]: '' }

    await push({
      search: queryString.stringify(newQueries),
    })

    await queryClient
      .fetchQuery(ORDERS_KEY)
      .then(() => {
        message.success('Фильтрация прошла успешно!')
      })
      .catch(() => {
        message.error('Произошла ошибка! Попробуйте повторить позже')
      })

    setIsDisabled(false)
  }

  return (
    <Space>
      {Object.entries(queries)
        .filter(([_, value]) => value)
        .map(([key, value]) => (
          <FilterTag
            id={key}
            key={key}
            title={
              key === 'organization_id'
                ? data!.holding.organizations.find((org) => org.id.toString() === value)!.title
                : value
            }
            isDisabled={isDisabled}
            handleFunction={deleteFilter}
          />
        ))}
    </Space>
  )
}

export default OrdersTags
