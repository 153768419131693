export const DashboardRoutes = {
  HOME: '/home',
  PROFILE: '/profile',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  ORDER_ADJUSTMENT: '/orders/:id/adjustment',
  CATALOG: '/catalog',
  CART: '/cart',
  CHECKOUT: '/cart/checkout',
  ORDER_COMPLETE: '/cart/order-complete',
  SAVED_CARTS: '/saved-carts',
  FAVORITES: '/favorites',
  FINANCES: '/finances',
  PRICE_LISTS: '/price-lists',
  DOCUMENTS: '/docs',
  SUPPORT: '/support',
  SUPPORT_HISTORY: '/support/history',
}
