import makeRequest from '@api/makeRequest'
import { ILoginRequest, IRestorePasswordData } from '@app/interfaces/user'

export const createSessionApi = () => {
  return makeRequest({
    url: '/sanctum/csrf-cookie',
  })
}
export const loginApi = (data: ILoginRequest) => {
  return makeRequest({
    url: '/api/auth/login',
    method: 'POST',
    data,
  })
}

export const forgotPassword = (email: string) => {
  return makeRequest({
    url: '/api/auth/recovery',
    method: 'POST',
    data: { email },
  })
}

export const logoutApi = () => {
  return makeRequest({
    url: '/api/auth/logout',
    method: 'POST',
  })
}

export const getAuthUserInformationApi = () => {
  return makeRequest({
    url: '/api/auth/me',
  })
}

export const changeUserAdditionalPhoneApi = (additional_phone: string) => {
  return makeRequest({
    url: '/api/auth/me',
    method: 'PUT',
    data: { additional_phone },
  })
}

export const resetPasswordApi = (data: IRestorePasswordData) => {
  return makeRequest({
    url: '/api/auth/reset-password',
    method: 'POST',
    data,
  })
}
