import React, { useMemo } from 'react'
import { ProductQuantity } from '@app/shared'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryClient } from '@app/index'
import { message } from 'antd'
import { changeItemQuantityInSavedCartApi } from '@api/rest/carts'
import { ICart } from '@app/interfaces/cart'
import { ALL_CARTS_KEY } from '@app/hooks/query/carts'
import _ from 'lodash'
import { CART_KEY } from '@app/hooks/query/active-cart'

export interface props {
  productId: number
  quantity: number
  inStock?: number
  cartId: number
  maxInStock: number
}

const SavedCartsCounter: React.FC<props> = ({
  quantity,
  maxInStock,
  inStock,
  productId,
  cartId,
}) => {
  const { mutateAsync, isLoading } = useMutation(changeItemQuantityInSavedCartApi, {
    onSuccess: async (response: AxiosResponse<ICart>) => {
      const oldData = queryClient.getQueryData<ICart[]>(ALL_CARTS_KEY)
      const activeCart = queryClient.getQueryData<ICart>(CART_KEY)

      if (activeCart?.id === cartId) {
        await queryClient.invalidateQueries(CART_KEY)
      }

      queryClient.setQueryData(
        ALL_CARTS_KEY,
        oldData!.map((cart) => (cart.id === response.data.id ? response.data : cart)),
      )
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const savedCartHandler = (quantity: number) => {
    return mutateAsync({
      cart_id: cartId,
      product_id: productId,
      quantity,
    })
  }

  const debounceQuery = useMemo(() => _.debounce(savedCartHandler, 800), [])

  return (
    <ProductQuantity
      maxInStock={maxInStock}
      quantity={quantity}
      inStock={inStock}
      isLoading={isLoading}
      handler={debounceQuery}
    />
  )
}

export default React.memo(SavedCartsCounter)
