import React from 'react'
import { Link } from 'react-router-dom'
import styles from './layout-header-cart.module.scss'
import icons from '@assets/images/icons.svg'
import { useActiveCart } from '@app/hooks/query/active-cart'
import { priceFormatter } from '@app/utils/priceFormatter'

interface props {}

const LayoutHeaderCart: React.FC<props> = () => {
  const { data } = useActiveCart()

  return (
    <Link className={styles.cart} to={'/cart'}>
      <div className={styles.wrapper}>
        <svg className={styles.icon}>
          <use href={icons + '#cart'} />
        </svg>
        <span className={styles.counter}>{data?.quantity}</span>
      </div>

      <span className={styles.price}>{priceFormatter(data?.total)} руб.</span>
    </Link>
  )
}

export default LayoutHeaderCart
