import React from 'react'
import { Button } from 'antd'
import styles from './muted-button.module.scss'

interface props {
  onClick?: () => void
  ghostOnMobile?: boolean
  type?: 'submit' | 'button' | 'reset'
  size?: 'large' | 'middle' | 'small'
  block?: boolean
  disabled?: boolean
  loading?:boolean
  children: React.ReactNode
}

const MutedButton: React.FC<props> = ({
  type,
  children,
  size = 'middle',
  onClick,
  ghostOnMobile,
  disabled = false,
  loading=false,
  block
}) => {
  return (
    <Button
      className={[styles.btn, ghostOnMobile ? styles.ghost : ''].join(' ')}
      type={'default'}
      htmlType={type || 'button'}
      size={size}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      block={block}
    >
      {children}
    </Button>
  )
}

export default React.memo(MutedButton)
