import React from 'react'
import { Tag } from 'antd'

export interface ProductBadgeProps {
  color: string | undefined | null
  title: string
}

const ProductBadge: React.FC<ProductBadgeProps> = ({ color, title }) => {
  return <Tag color={color || 'default'}>{title}</Tag>
}

export default ProductBadge
