import React from 'react'

interface props {
  volume: number
}

const CartVolume: React.FC<props> = ({ volume }) => {
  return (
    <React.Fragment>
      <strong>Объем: </strong>{' '}
      <span>
        {volume} м<sup>3</sup>
      </span>
    </React.Fragment>
  )
}

export default CartVolume
