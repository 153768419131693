import React, { useState } from 'react'
import styles from './auth-form.module.scss'
import { Button, Checkbox, Form, Input, message } from 'antd'
import { createSessionApi, loginApi } from '@api/rest/auth'
import { AxiosResponse } from 'axios'
import { ILoginRequest, ILoginResponse } from '@app/interfaces/user'
import { useHistory, useLocation } from 'react-router-dom'
import { DashboardRoutes } from '@app/constants/dashboard-routes'
import { useMutation } from 'react-query'
import { AUTHENTICATED } from '@app/constants/auth'
import { RestorePasswordForm } from '@app/pages/auth-page/components'

interface props {}

const AuthForm: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()

  const { mutateAsync } = useMutation(loginApi, {
    onSuccess: (response: AxiosResponse<ILoginResponse>) => {
      let { from }: any = location.state || { from: { pathname: DashboardRoutes.HOME } }
      message.success('Вы успешно авторизовались')
      localStorage.setItem(AUTHENTICATED, 'true')
      setIsLoading(false)
      history.replace(from)
    },
    onError: () => {
      message.error('Ошибка авторизации! Возможно вы ввели неверные данные.')
      setIsLoading(false)
    },
  })

  const onFinish = async (values: ILoginRequest) => {
    setIsLoading(true)
    await createSessionApi()
    await mutateAsync(values)
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name={'login'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input size={'large'} placeholder={'Введите логин'} />
      </Form.Item>
      <Form.Item
        name={'password'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          {
            min: 3,
            message: 'Минимальная длина пароля 3 символа',
          },
        ]}
      >
        <Input.Password className={styles.password} size={'large'} placeholder={'Введите пароль'} />
      </Form.Item>

      <Form.Item>
        <div className={styles.wrapper}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Запомнить пароль</Checkbox>
          </Form.Item>

          <RestorePasswordForm />
        </div>
      </Form.Item>

      <Form.Item>
        <Button
          type={'primary'}
          htmlType={'submit'}
          block
          size={'large'}
          disabled={isLoading}
          loading={isLoading}
        >
          Войти
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthForm
