import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import axios from 'axios'

export const getAllProductsApi = () => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()

  return makeRequest({
    url: '/api/lk/catalog',
    cancelToken: source.token,
    params: queryString.parse(history.location.search),
  })
}
