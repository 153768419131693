import { useQuery, UseQueryOptions } from 'react-query'
import { IOrder, IOrderResponse } from '@app/interfaces/orders'
import { getAllOrdersApi, getLatestOrdersApi, getOrderByIdApi } from '@api/rest/orders'
import { AxiosResponse } from 'axios'

export const ORDERS_KEY = 'orders'
export const LATEST_ORDERS_KEY = 'latest-orders'

export function useOrders() {
  return useQuery<IOrderResponse, Error>(ORDERS_KEY, () =>
    getAllOrdersApi().then((response: AxiosResponse<IOrderResponse>) => response.data),
  )
}

export function useLatestOrders(options?: UseQueryOptions<IOrder[], Error>) {
  return useQuery<IOrder[], Error>(
    LATEST_ORDERS_KEY,
    () => getLatestOrdersApi().then((response: AxiosResponse<IOrder[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useOrder(id: string) {
  return useQuery<IOrder, Error>([ORDERS_KEY, id], () =>
    getOrderByIdApi(id).then((response: AxiosResponse<IOrder>) => response.data),
  )
}
