import React from 'react'
import { SUPPORT_KEY, useSupportHistory } from '@app/hooks/query/support'
import { PageHeader, Skeleton, Table } from 'antd'
import { Container, ErrorBoundary, PageInfo, Pagination, TableMobileTitle } from '@app/shared'
import { ColumnType } from 'antd/lib/table'
import { ISupportAppeal, ISupportAppealText } from '@app/interfaces/support'

export interface SupportHistoryPageProps {}

const SupportHistoryPage: React.FC<SupportHistoryPageProps> = () => {
  const { data: response, isLoading, isError } = useSupportHistory()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const columns: ColumnType<ISupportAppeal>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (render: string) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} />
          {render}
        </React.Fragment>
      ),
    },
    {
      title: 'Тема обращения',
      dataIndex: 'text',
      render: (text: ISupportAppealText) => (
        <React.Fragment>
          <TableMobileTitle title={'Тема обращения'} />
          {text.subject}
        </React.Fragment>
      ),
    },
    {
      title: 'Текст обращения',
      dataIndex: 'text',
      render: (text: ISupportAppealText) => (
        <React.Fragment>
          <TableMobileTitle title={'Текст обращения'} />
          {text.message}
        </React.Fragment>
      ),
    },
    {
      title: 'Дата обращения',
      dataIndex: 'created_at',
      render: (created_at: string) => (
        <React.Fragment>
          <TableMobileTitle title={'Дата создания'} />
          {new Date(created_at).toLocaleDateString()}
        </React.Fragment>
      ),
    },
  ]

  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={SUPPORT_KEY} />

  return (
    <Container>
      <PageInfo title={'История обращений'} />
      <PageHeader title={'История обращений'} />
      <Table
        columns={columns}
        pagination={false}
        footer={tableFooter}
        rowKey={'id'}
        scroll={{ x: true }}
        bordered={false}
        dataSource={data}
      />
    </Container>
  )
}

export default SupportHistoryPage
