import React from 'react'
import styles from './layout-footer.module.scss'

interface props {}

const LayoutFooter: React.FC<props> = () => {
  return (
    <div className={styles.footer}>Bradex - Simple Life Solutions {new Date().getFullYear()}</div>
  )
}

export default LayoutFooter
