import React from 'react'
import { Pagination, TableMobileTitle } from '@app/shared'
import { OrderInfo, OrderRenew, OrderStatus } from '../index'
import { IOrder, IOrderStatus } from '@app/interfaces/orders'
import { IPagination } from '@app/interfaces'
import { Table } from 'antd'
import { ORDERS_KEY } from '@app/hooks/query/order'
import { priceFormatter } from '@app/utils/priceFormatter'
import isEqual from 'react-fast-compare'

interface props {
  data: IOrder[]
  pagination: IPagination
}

const OrdersTable: React.FC<props> = ({ data, pagination }) => {
  const renderTitle = () => 'Мои заказы'

  const renderFooter = () => <Pagination pagination={pagination} fetchingQueryKey={ORDERS_KEY} />

  const columns = [
    {
      title: 'Наименование',
      key: 'name',
      render: ({ id, number, organization }: IOrder) => (
        <OrderInfo id={id} orderNumber={number} organization_name={organization.title} />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: IOrderStatus, { updated_at }: IOrder) => (
        <OrderStatus
          statusTitle={status.title}
          statusCode={status.code}
          orderUpdateDate={updated_at}
        />
      ),
    },
    {
      title: 'Сумма заказа',
      dataIndex: 'total',
      render: (text: string) => (
        <span>
          <TableMobileTitle title={'Цена'} /> <strong>{priceFormatter(text)} руб.</strong>
        </span>
      ),
    },
    {
      title: 'Дата заказа',
      dataIndex: 'created_at',
      render: (date: string) => (
        <span>
          <TableMobileTitle title={'Дата заказа'} />
          {new Date(date).toLocaleDateString()}
        </span>
      ),
    },
    {
      key: 'actions',
      render: ({ id }: IOrder) => <OrderRenew orderId={id} />,
    },
  ]

  return (
    <Table
      columns={columns}
      bordered={false}
      title={renderTitle}
      rowKey={'id'}
      pagination={false}
      scroll={{ x: true }}
      footer={renderFooter}
      dataSource={data}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return (
    isEqual(prevProps.data, nextProps.data) && isEqual(prevProps.pagination, nextProps.pagination)
  )
}

export default React.memo(OrdersTable, propsAreEqual)
